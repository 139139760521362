import correct_mark from '../../../images/Vector.svg';

export default function Alert({ showAlert, alertStyle, alertMessage, hideAlert }){

    return (
        <div className={'position-fixed max-width-700 top-20 z-3 w-100 ' + showAlert }>
                <div>
                    <div className='mx-auto row w-95 rounded-2' style={alertStyle}>
                        <div className='col-2'>
                            <div className='d-flex justify-content-center align-items-center h-100 w-100'>
                                <div className='bg-black bg-opacity-25 p-1 text-center w-100 rounded-3'>
                                    {
                                        alertMessage.success ? 
                                        <><img src={correct_mark} alt='Tick' className='img-fluid w-70' /></>
                                        : <>&times;</>
                                    }
                                   
                                </div>
                            </div>
                        </div>
                        <div className='col-9'>
                            <div className='d-flex justify-content-center py-3 h-100 w-100 flex-column text-white'>
                                <p className='font-weight-bolder mb-0'>{alertMessage.mode}</p>
                                <p className='font-size-12 mb-0'>{alertMessage.message}</p>
                            </div>  
                        </div>
                        <div className='col-1 p-0 position-relative'>
                            <button className='btn m-0 p-0 text-white-50 top-5 font-size-25' onClick={() => {hideAlert()} }>&times;</button>
                        </div>
                        
                    </div>
                </div>
            </div>
    )
}