import React, { useEffect, useState } from 'react';
import Splash from './Splash';
import Carousel from './Carousel';
import AuthOptions from './AuthOptions';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import { Link } from 'react-router-dom';

function Auth({ baseURL, clientID }) {

    const [isSplashVisible, setIsSplashVisible] = useState('d-flex');

    const [classLoginScreen, setClassLoginScreen] = useState('');

    const [isAuthOptionVisible, setIsAuthOptionVisible] = useState(false);

    const [isAuthOptionVisibleClass, setIsAuthOptionVisibleClass] = useState('d-none');

    const [authOptionPage, setAuthOptionPage] = useState('login');

    useEffect(() => {
        setTimeout(function () {
            setIsSplashVisible('opacity-0')
            setTimeout(function () {
                setIsSplashVisible('d-none')
            }, 1000);
        }, 3000);
    }, []);

    function setAuthScreen(page) {
        if (page === 'authoptionlogin') {
            setClassLoginScreen('opacity-0');
            setIsAuthOptionVisibleClass('opacity-0');
            setIsAuthOptionVisibleClass('opacity-100')
            
            setTimeout(function () {
                setIsAuthOptionVisible(true);
            }, 150);
        } else if (page === 'authoptionjoin') {
            setClassLoginScreen('opacity-0');
            setIsAuthOptionVisibleClass('opacity-0');
            setAuthOptionPage('join');
            setIsAuthOptionVisibleClass('opacity-100')

            setTimeout(function () {
                setIsAuthOptionVisible(true);
            }, 150);
        } else if (page === 'login-signup') {
            setIsAuthOptionVisibleClass('opacity-0')
            setClassLoginScreen('opacity-100');

            setTimeout(function () {
                setIsAuthOptionVisible(false);
            }, 150);
        }
    }


    return (
            <AnimatedPage className='h-100'>
                <Splash isSplashVisible={isSplashVisible} />
                <div className='h-100 position-relative w-100 bg-white overflow-hidden'>
                    <div className='h-80 position-absolute top-0 w-100'>
                        <Carousel baseURL={baseURL} clientID={clientID} />
                    </div>
                    {
                        isAuthOptionVisible === false ? (
                            <div className={'position-absolute bg-white h-20 bottom-0 w-100 z-1 py-2 overflow-hidden transition-0-5-ease-in ' + classLoginScreen}>
                                <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <div className='d-flex flex-column w-95 transition-0-5-ease'>
                                        <Link to='/login' className='btn btn-primary btn-lg py-2-5 bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12'>
                                            Log in
                                        </Link>
                                        <Link to='/join' className='btn btn-white btn-lg py-2-5 shadow-wafaa-btn mt-3 font-size-12 text-wafaa-dark-blue'>
                                            Join us
                                        </Link>
                                    </div>
                                </div>
                            </div>)
                            : (
                                <div className={'h-20 transition-0-5-ease bg-white mt-n5 overflow-x-hidden position-absolute bottom-10 w-100 rounded-end-4 rounded-start-4 ' + isAuthOptionVisibleClass}>
                                    <AuthOptions authOptionPage={authOptionPage} setAuthScreen={setAuthScreen}/>
                                </div>)
                    }


                </div>
            </AnimatedPage>
    );
}

export default Auth;
