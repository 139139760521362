import moment from 'moment';
import { Link } from 'react-router-dom';

function SecondOfferBox({ promotion }) {
    return (
        <Link to={'/offers/single/' + promotion.PROMO_CODE} className='text-decoration-none'>
        <div className='w-95 mx-auto shadow-wafaa-bg rounded-2 mb-3 bg-wafaa-white-2'>
            <div className='row p-0 m-0 justify-content-between pt-3'>
                <div className='col-7 ps-3'>
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <p className='mb-0 font-size-16 font-weight-600 text-wafaa-dark-blue-2 mb-0'>
                            { promotion.PROMO_DESC }
                        </p>
                    </div>
                </div>
                <div className='col-5 ps-0 ms-0'>
                    <img src={ promotion.PROMO_IMAGE } alt='Model' className='height-100 w-100 h-100 object-fit-cover rounded-4 rounded-start-0' />
                </div>
            </div>
            <div className='row p-0 m-0 justify-content-between pt-3'>
                <div className='col-6 ps-3'>
                    <div className='d-flex flex-column justify-content-between h-100 text-wafaa-grey font-size-13 font-weight-400'>
                        <p className='mb-0 py-1'>
                            STARTS { moment(promotion.VALID_FROM).format('DD/MM/YYYY') }
                        </p>
                        <p className='mb-0 pt-1 pb-3'>
                            ENDS { moment(promotion.VALID_TO).format('DD/MM/YYYY') }
                        </p>
                    </div>
                </div>
                <div className='col-6 ps-0 ms-0 text-wafaa-grey font-size-13 font-weight-400 d-flex justify-content-end d-none align-items-center'>
                    <div className="form-check form-switch">
                        <input className="form-check-input wafaa-white-switch" type="checkbox" id="flexSwitchCheckDefault" />
                    </div>
                </div>
            </div>

        </div>
        </Link>
    )
}

export default SecondOfferBox;