import React, { useEffect, useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import Navbar from '../Navbar/Navbar';
import '../../css/fontawesome.css';
import { useAuth } from '../../context/userContext';

function SharePoints({ baseURL, clientID }) {
    const [customerData, setCustomerData] = useState({});

    const [userData, setUserData] = useState({});

    const [rangeValue, setRangeValue] = useState(0);

    const [pointsToRedeem, setPointsToRedeem] = useState(0);

    const [calculatedPoints, setCalculatedPoints] = useState(0);

    const [customerShareExist, setCustomerShareExist] = useState(true);

    const { authUser } = useAuth();

    useEffect(() => {
        if (authUser) {
            getCustomerData(baseURL);
            setUserData(authUser);
        }
    }, [authUser]);

    useEffect(() => {
        if (pointsToRedeem > 0) {
            getCalculatedPoints();
        }else{
            setCalculatedPoints(0);
        }
    }, [pointsToRedeem]);

    const getCalculatedPoints = async () => {
        const payLoad = {
            DATA: {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "TYPE_NAME": "CAL_POINTS",
                "POINTS": pointsToRedeem,
                "CLIENT_ID": clientID
            }
        }
        const request = await fetch(`${baseURL}/CalculatePoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        if (response.STATUS) {
            setCalculatedPoints(response.REEDEEM_AMOUNT);
        } else {
            console.error(response.MESSAGE);
        }
    }

    const getCustomerData = async (baseURL) => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER",
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setCustomerData(response);
    }

    const submitShareForm = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const payLoad = {
            DATA: {
                "EMAIL_SENDER": customerData.Email,
                "MOBILE_NUM_SENDER": customerData.MOBILE,
                "EMAIL_RECEIVER": formData.get('recipient_email'),
                "MOBILE_NUM_RECEIVER": formData.get('recipient_mobile'),
                "SHARE_POINTS": pointsToRedeem,
                "CLIENT_ID": clientID
            }
        }
        const request = await fetch(`${baseURL}/LoySharePoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            setCustomerShareExist(true);
            alert('Successful')
        } else {
            setCustomerShareExist(false);
        }
    }

    const handleInput = (event) => {
        setPointsToRedeem(event.currentTarget.value);
    }

    const handlePoints = (number) => {
        const result = parseFloat(number) + parseFloat(pointsToRedeem);
        if (result > -1) {
            setPointsToRedeem(result);
        }
    }

    const handleRangeValueChange = (event) => {
        setRangeValue(event.target.value)
        const percentage = parseFloat(event.target.value)/parseFloat(100);
        const result = percentage * parseFloat(customerData.CURRENTPOINTS);
        setPointsToRedeem(result.toFixed(0));
    }

    return (
        <AnimatedPage>
            <div className='position-sticky z-2'>
                <div className={'transition-0-5-bounce'}>
                    <PageTitle hideBackButton={false} name='SEND POINTS'>
                        <div className='w-95 mx-auto d-flex justify-content-between mt-3 font-size-13 text-start text-white'>
                            <div className='col-4 p-0 m-0'>
                                <p className='font-weight-400 m-0'>Points balance</p>
                                <p className='font-weight-700'>{customerData.CURRENTPOINTS ? customerData.CURRENTPOINTS : "00"}</p>
                            </div>
                            <div className='col-4 p-0 m-0'>
                                <p className='font-weight-400 m-0'>Points value</p>
                                <p className='font-weight-700'>{customerData.POINTS_VALUE ? customerData.POINTS_VALUE : '0'} AED</p>
                            </div>
                            <div className='col-4 p-0 m-0'>
                                <p className='font-weight-400 m-0'>Your type</p>
                                <p className='font-weight-700'>{userData ? userData.CARD_TYPE : ''}</p>
                            </div>
                        </div>
                    </PageTitle>
                </div>
            </div>
            <form className='px-3 mt-3 position-relative h-90 bg-wafaa-grey' onSubmit={(event) => submitShareForm(event)}>
                <div className='py-2'>
                    <p className='font-size-13 font-weight-800 text-black'>Recipient Details</p>
                    <div className='bg-white shadow p-3 rounded-3'>
                        <div className='d-flex justify-content-between mb-3'>
                            <label className='col-2 text-wafaa-grey font-size-14'>Email</label>
                            <input className='col-9 border-0 font-size-14 ps-2 text-dark' required name='recipient_email' type='text' placeholder='Enter Email' />
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label className='col-2 text-wafaa-grey font-size-14'>Mobile</label>
                            <input className='col-9 border-0 font-size-14 ps-2 text-dark' required name='recipient_mobile' type='tel' placeholder='Enter Mobile' />
                        </div>
                    </div>
                    <div className={'font-size-12 text-end pt-3 text-danger ' + (customerShareExist ? 'd-none' : 'd-block') }>
                        Customer does not exist
                    </div>
                </div>

                <div className='mt-3'>
                    <p className='font-size-13 font-weight-800 text-black'>You Send</p>
                    <p className='font-size-13 font-weight-800 text-black'>Points</p>
                    <div className='bg-white shadow rounded-3'>
                        <div className="input-group mb-3 justify-content-between">
                            <button type='button' onClick={() => handlePoints(-1)} className="btn font-size-20 font-weight-800 text-dark">-</button>
                            <input name='points' required value={pointsToRedeem} max={customerData.CURRENTPOINTS ? customerData.CURRENTPOINTS : 0} onChange={(event) => handleInput(event)} type="number" pattern="\d*" placeholder='Amount(Points)' className='col-4 number-textfield border-0 text-center font-size-13' />
                            <button type='button' onClick={() => handlePoints(1)} className="btn font-size-20 font-weight-800 text-dark">+</button>
                        </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <div className="position-relative w-100">
                        <input className="track w-100 m-0 bg-transparent border border-1 border-wafaa-grey-2 rounded-1 outline-none position-absolute z-2 top-0" type="range" step="25" min="0" max="100" value={rangeValue} onChange={(event)=>handleRangeValueChange(event)} />
                        <ul className="m-0 p-0 d-flex align-items-center justify-content-between w-100 font-size-14 position-absolute z-1 scale">
                            <li className="step width-20 height-20 bg-wafaa-grey-2 shadow-sm"></li>
                            <li className="step width-20 height-20 bg-wafaa-grey-2 shadow-sm"></li>
                            <li className="step width-20 height-20 bg-wafaa-grey-2 shadow-sm"></li>
                            <li className="step width-20 height-20 bg-wafaa-grey-2 shadow-sm"></li>
                            <li className="step width-20 height-20 bg-wafaa-grey-2 shadow-sm"></li>
                        </ul>
                    </div>
                </div>

                <div className='mt-5 py-3'>
                <p className='font-size-13 font-weight-800 text-black'>Value (AED)</p>
                    <div className='bg-white shadow rounded-3'>
                        <div className="input-group mb-3 justify-content-between">
                            <input name='points_value' readOnly value={calculatedPoints} placeholder='Total(AED)' className='border-0 font-size-13 number-textfield py-2 text-center w-100' />
                        </div>
                    </div>
                </div>

                <div className='position-absolute bottom-60 mb-4 w-100'>
                    <button className='w-90 mx-auto btn bg-wafaa-purple text-white'>
                        Share
                    </button>
                </div>
            </form>
            <Navbar activePage='profile' />
        </AnimatedPage>
    );
}

export default SharePoints;
