import React, { useEffect, useState, useRef } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';
import credit_card from '../../images/icons/ic_baseline-credit-card.svg';
import pdf from '../../images/icons/image 11.png';
import { useNavigate } from 'react-router-dom';
import '../../css/fontawesome.css';
import { useAuth } from '../../context/userContext';
import facebook from '../../images/mingcute_facebook-fill.png';
import instagram from '../../images/lets-icons_insta.png';
import bg_circles from '../../images/bg-circle.svg';
import logo from '../../images/logo.png';
import send from '../../images/mingcute_send-fill.svg';

import JsBarcode from 'jsbarcode';

import Cookies from "js-cookie";
import { useAppSetting } from '../../context/appSettingContext';

function Profile({ baseURL, clientID }) {
    const navigate = useNavigate();
    const [bigDashClass, setBigDashClass] = useState('height-50 opacity-100');
    const [customerData, setCustomerData] = useState({});
    const [socialLinks, setSocialLinks] = useState({});
    const [userData, setUserData] = useState({});

    const { clientAccess, theme, setTheme } = useAppSetting();
    

    const { authUser,
        setAuthUser,
        setIsLoggedIn } = useAuth();

    useEffect(() => {
        if (authUser) {
            setBigDashClass('height-50 opacity-25');
            setTimeout(() => {
                setBigDashClass('height-150 opacity-100');
            }, 300);
            getCustomerData(baseURL);
            getSocialLinks(baseURL);
            setUserData(authUser);
            JsBarcode('.js-bar-code', (authUser ? authUser.CUST_CODE : '0000'), {
                displayValue: false
            });
        }
    }, [authUser]);

    const getCustomerData = async (baseURL) => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER",
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setCustomerData(response);
    }

    const getSocialLinks = async (baseURL) => {
        const payLoad = {
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/DownloadSocialLinks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if(response.STATUS){
            const socials = {};
            for (const socialLink of response.SOCIALS) {
                socials[socialLink.NAME] = `https://${socialLink.LINK}`;
            }
            setSocialLinks(socials);
        }
    }

    const logOut = () => {
        setAuthUser({});
        setIsLoggedIn(false);
        Cookies.remove('wafaa-user-data');
        navigate('/');
    }

    const downloadBrochure = async () => {
        const payLoad = { "DATA": { "AUTH_KEY": "TXlDb206TG95QVBJMTIz", "CLIENT_ID": clientID } };
        const request = await fetch(`${baseURL}/DownloadBrouchre`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            const fileURL = new URL(response.DATA[0].FilePath);
            await download(response.DATA[0].FilePath, fileURL.pathname.substring(fileURL.pathname.lastIndexOf('/') + 1));
        } else {
            alert('Failed to download');
        }
    }

    const download = async (url, name) => {
        if (!url) {
            throw new Error("Resource URL not provided! You need to provide one");
        }
        const request = await fetch(url);

        const blob = await request.blob();

        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";
        a.download = name
        document.body.appendChild(a);
        a.click();
    };

    const handleThemeSelection = (event) => {
        if(event.target.checked){
            setTheme('dark');
            Cookies.set('wafaa-user-data-theme', 'dark');
        }else{
            setTheme('light');
            Cookies.set('wafaa-user-data-theme', 'light');
        }
    }

    return (
        <AnimatedPage>
            <div className="modal fade max-width-700 start-50" style={{
                transform: "translateX(-50%)"
            }}
                id="barCodeModal" tabIndex="-1" aria-labelledby="barCodeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered width-300 mx-auto max-width-700">
                    <div className="modal-content">
                        <div className='modal-header border-white'>
                            <button type="button" className="btn btn-close position-relative z-1 end-0 pe-0 text-white" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body text-center height-350 p-0">
                            <div className='overflow-hidden rounded-2 width-400 height-300' style={{
                                transform: `translateY(0px) translateX(-3.2rem) rotate(90deg)`
                            }}>
                                <div>
                                    <p className='mb-0 bg-wafaa-purple text-white font-size-10 d-flex justify-content-end align-items-center py-2 pe-2'>
                                        www.truevalue.com |
                                        <img src={facebook} alt='Facebook' className='height-20 ms-2 me-1' />
                                        <img src={instagram} alt='Instagram' className='height-20 me-2' />
                                        TrueValueUAE
                                    </p>
                                </div>
                                <div className='text-black bg-black py-2'>
                                    T
                                </div>
                                <img className='js-bar-code' src='#' alt='Barcodes' />
                                <div className='w-100 bg-wafaa-purple py-3' style={{
                                    backgroundImage: `url(${bg_circles})`
                                }}>
                                    <div className='row m-0 p-0 justify-content-between'>
                                        <div className='col-4 text-center'>
                                            <img src={logo} alt='Logo' className='height-60' />
                                            <div className='d-flex justify-content-between font-size-7 align-items-center'>
                                                <span className='text-white'>UAE</span>
                                                <span className='rounded-circle height-5 width-5 overflow-hidden text-white bg-white'>X</span>
                                                <span className='text-white'>QATAR</span>
                                                <span className='rounded-circle height-5 width-5 overflow-hidden text-white bg-white'>X</span>
                                                <span className='text-white'>SAUDI</span>
                                                <span className='rounded-circle height-5 width-5 overflow-hidden text-white bg-white'>X</span>
                                                <span className='text-white'>OMAN</span>
                                            </div>
                                        </div>
                                        <div className='col-7 font-size-7 text-start text-white'>
                                            True Value Rewards Cards are the exclusive property of MyCOM Group. In the event of discovering a lost card, we kindly request its return to the nearest MyCOM Tech outlet. For assistance or inquiries, please reach out to us via
                                            <p className='font-size-7 mb-0'>Email - truevalue@mycom.com.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-sticky z-2'>
                <div className={'transition-0-5-bounce ' + bigDashClass}>
                    <PageTitle hideBackButton={true}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='align-items-center text-white px-0 d-flex justify-content-between'>
                                <img src={userData ? userData.PROFILE_IMAGE : ''} alt='Profile pic' className='height-60 width-60 rounded-circle border border-3 border-wafaa-purple object-fit-cover' />
                                <p className='mb-0 font-size-22 font-weight-700'>{userData ? userData.CUST_NAME : ''}</p>
                            </div>

                            <div className='d-block me-3' data-bs-toggle="modal" data-bs-target="#barCodeModal">
                                <img src={credit_card} alt='Credit card' className='h-auto' />
                            </div>
                        </div>
                        <div className='w-95 mx-auto d-flex justify-content-between mt-3 font-size-13 text-start text-white'>
                            <div className='col-4 p-0 m-0'>
                                <p className='font-weight-400 m-0'>Points balance</p>
                                <p className='font-weight-700'>{customerData.CURRENTPOINTS ? customerData.CURRENTPOINTS : "00"}</p>
                            </div>
                            <div className='col-4 p-0 m-0'>
                                <p className='font-weight-400 m-0'>Points value</p>
                                <p className='font-weight-700'>{customerData.POINTS_VALUE ? customerData.POINTS_VALUE : '0'} AED</p>
                            </div>
                            <div className='col-4 p-0 m-0'>
                                <p className='font-weight-400 m-0'>Your type</p>
                                <p className='font-weight-700'>{userData ? userData.CARD_TYPE : ''}</p>
                            </div>
                        </div>
                        <Link to='/share-points' className={ 'text-decoration-none w-95 mx-auto border border-1 border-wafaa-purple-lighter d-flex align-items-center p-2 rounded-2 ' + 
                            (
                                (clientAccess.find((access) => access.access_desc === 'Share Points')).enabled
                                        ? 
                                    '' : 'd-none'
                            ) 
                            }>
                            <img className='width-20' src={send} alt='Send' />
                            <div className='col text-white font-size-12 ps-3'>
                                Send Points
                            </div>
                        </Link>
                    </PageTitle>
                </div>
            </div>


            <div className='w-100 h-95 overflow-y-scroll pb-5 transition-0-5-bounce bg-wafaa-white-3 mt-2 pt-3 pb-5'>

                <div className='w-95 mx-auto px-2'>
                    <p className='mb-0 font-size-14 font-weight-500 text-wafaa-black-1'>TRUE VALUE</p>
                </div>

                <div className='shadow bg-wafaa-white-2 mx-3 my-3 rounded-2 py-1'>
                    <Link to='/my-profile' className='text-decoration-none text-dark w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 pb-2 font-size-14 d-flex justify-content-between font-weight-500 p-2'>
                        <span className='text-wafaa-black-1'>My account</span>
                        <span className='text-wafaa-grey-1'>
                            &gt;
                        </span>
                    </Link>

                    <p className={'w-100 mx-auto border-1 border-top-0 border-end-0 border-start-0 font-size-14 mb-0 d-flex justify-content-between font-weight-500 p-2 ' + 
                        (
                            (clientAccess.find((access) => access.access_desc === 'Dark Mode')).enabled
                                ? 
                            '' : 'd-none'
                        )
                    }>
                        <span className='text-wafaa-black-1'>Light/Dark Mode</span>
                        <div>
                            <div class="form-check form-switch">
                                <input class="form-check-input" style={{
                                    transform: "scale(1.8)"
                                }} checked={theme==='dark'? true : false} onChange={(event) => handleThemeSelection(event)} type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                            </div>
                        </div>
                    </p>
                    
                </div>

                <div className='w-95 mx-auto px-2'>
                    <p className='mb-0 font-size-14 font-weight-500 text-wafaa-black-1'>ABOUT</p>
                </div>

                <div className='shadow bg-wafaa-white-2 mx-3 my-3 rounded-2 py-1'>
                    <Link to='/about' className='text-decoration-none mb-0 w-100 mx-auto border-0 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                        <span className='text-wafaa-black-1'>About True Value</span>
                        <span className='text-wafaa-grey-1'>
                            &gt;
                        </span>
                    </Link>

                </div>

                <div className='w-95 mx-auto px-2'>
                    <p className='mb-0 font-size-14 font-weight-500'>REACH OUT TO US</p>
                </div>

                <div className='shadow bg-wafaa-white-2 mx-3 my-3 rounded-2 py-1'>
                    <Link to='/stores' className='text-decoration-none w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 font-size-14 mb-0 d-flex justify-content-between font-weight-500 p-2'>
                        <span className='text-wafaa-black-1'>Stores</span>
                        <span className='text-wafaa-grey-1'>
                            &gt;
                        </span>
                    </Link>

                    <Link to='/feedback' className='text-decoration-none border-1 border-top-0 border-end-0 border-start-0 text-dark w-100 mx-auto pb-2 font-size-14 d-flex justify-content-between font-weight-500 p-2'>
                        <span className='text-wafaa-black-1'>Feedback</span>
                        <span className='text-wafaa-grey-1'>
                            &gt;
                        </span>
                    </Link>

                    <p onClick={() => { downloadBrochure() }} className='d-none w-100 mx-auto mb-0 border-top-0 border-end-0 border-start-0 mb-0 p-2 font-size-14 font-weight-500'>
                        <img src={pdf} alt='PDF Icon' className='width-30' />
                        <span>Download Brochure</span>
                    </p>

                    <p className='d-none w-100 mx-auto border border-1 mb-0 border-top-0 border-end-0 border-start-0 mb-0 p-2 font-size-14 d-flex justify-content-between font-weight-500'>
                        <span>Delete Account</span>
                        <span className='text-wafaa-grey'>
                            &gt;
                        </span>
                    </p>

                </div>

                <div onClick={() => { logOut() }} className='bg-wafaa-white-2 mx-3 rounded-2'>
                    <p className='p-2 text-wafaa-black-1'>
                        <span className='fa-solid fa-power-off'></span>
                        <span className='ms-2'>Sign Out</span>
                    </p>
                </div>

                <div className='col mx-auto d-flex justify-content-around'>
                    <a href={socialLinks.Facebook ? socialLinks.WhatsApp : 'https://whatsapp.com'}>
                        <span className='text-secondary font-size-20 fa-brands fa-whatsapp'></span>
                    </a>

                    <a href={socialLinks.Facebook ? socialLinks.Facebook : 'https://facebook.com'}>
                        <span className='text-secondary font-size-20 fa-brands fa-facebook'></span>
                    </a>

                    <a href={socialLinks.Twitter ? socialLinks.Twitter : 'https://twitterr.com'}>
                        <span className='text-secondary font-size-20 fa-brands fa-twitter'></span>
                    </a>

                    <a href={socialLinks.Instagram ? socialLinks.Instagram : 'https://instagram.com'}>
                        <span className='text-secondary font-size-20 fa-brands fa-instagram'></span>
                    </a>

                    <a href={socialLinks.LinkedIn ? socialLinks.LinkedIn : 'https://linkedin.com'}>
                        <span className='text-secondary font-size-20 fa-brands fa-linkedin'></span>
                    </a>

                    <a href={socialLinks.TikTok ? socialLinks.TikTok : 'https://tiktok.com'}>
                        <span className='text-secondary font-size-20 fa-brands fa-tiktok'></span>
                    </a>
                </div>

                <p className='text-white w-100'></p>

            </div>
            <Navbar activePage='profile' />
        </AnimatedPage>
    );
}

export default Profile;
