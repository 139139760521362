import { useState, useEffect } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import SingleStore from './SingleStore';

function Stores({ appSettings }) {

    const [stores, setStores] = useState([]);

    const { ClientId: clientID, AppBaseURL1 } = appSettings;


    useEffect(() => {
        loadStores();
    }, []);

    async function loadStores(){
        const payLoad = {
            "DATA": {
                "client_id": clientID
            }
        };

        const request = await fetch(`${AppBaseURL1}Loy_GetLocationDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        if (request.ok && response.STATUS) {
            setStores(response.DATA);
        }
    }

    return (
        <AnimatedPage>
            <div className='position-sticky z-2'>
                <PageTitle name='STORES' />
            </div>


            <div className='w-100 h-85 overflow-y-scroll pb-5 pt-3 transition-0-5-bounce bg-wafaa-white-3'>
                {
                    stores.map((store, index) => (
                        <SingleStore store={store} key={index}/>
                    ))
                }
            </div>

        </AnimatedPage>
    );
}

export default Stores;
