import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';

function SingleAboutItemPage({ store }) {
    const location = useLocation();

    const navigate = useNavigate();

    const aboutDetails = location.state;

    useEffect(() => {
        if (!aboutDetails) {
            navigate('/about');
        }
    }, [])
    return (
        <AnimatedPage className='overflow-hidden'>
            <div className='position-sticky z-2'>
                <PageTitle name={aboutDetails.Heading} />
            </div>
            <div className='font-size-13 mt-4'>
                { aboutDetails.Content }
            </div>
        </AnimatedPage>
    )
}
export default SingleAboutItemPage;