import React, { useEffect, useRef, useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import Navbar from '../Navbar/Navbar';
import qr_code from '../../images/icons/material-symbols_qr-code-2.svg';
import close_icon from '../../images/icons/material-symbols_close.svg';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useCountdown } from '../../hooks/CountDown';

function SingleOffer({ baseURL, clientID }) {

    const { promocode } = useParams();

    const homeSection = useRef();

    const [upperSectionClass, setUpperSectionClass] = useState('height-250');

    const [smallImageClass, setSmallImageClass] = useState('bottom-10');

    const [offerDetails, setOfferDetails] = useState(null);

    const [countdownDate, setCountdownDate] = useState(new Date());

    const [days, hours, minutes, seconds] = useCountdown(countdownDate);

    useEffect(() => {
        homeSection.current.addEventListener('scroll', (e) => {
            if (e.target.scrollTop > 30) {
                setUpperSectionClass('height-200');
                setSmallImageClass('bottom--25');
            } else if(e.target.scrollTop === 0) {
                setUpperSectionClass('height-250');
                setSmallImageClass('bottom-10');
            }
        });
        getPromotions(baseURL);
    }, []);

    const getPromotions = async (baseURL) => {
        let payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "LoyGetPromotions",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "LOC_ID": "001",
                "LOY_EXC" : 0,
                "CLIENT_ID": clientID
            }
        };
        let request = await fetch(`${baseURL}/LoyGetPromotions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        let response = await request.json();
        let offer = response.PROMOTIONS.find((promotion) => {
            return promotion.PROMO_CODE === promocode
        });
        if(offer){
            setOfferDetails(offer);
            setCountdownDate(new Date(offer.VALID_TO));
        }else{
            payLoad = {
                "DIV_DES": "",
                "DIV_ID": "1",
                "FUNCTION": "LoyGetPromotions",
                "SEND_KEY": "123456",
                "DATA": {
                    "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                    "LOC_ID": "001",
                    "LOY_EXC" : 1,
                    "CLIENT_ID": clientID
                }
            };
            request = await fetch(`${baseURL}/LoyGetPromotions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            });
    
            response = await request.json();
            offer = response.PROMOTIONS.find((promotion) => {
                return promotion.PROMO_CODE === promocode
            });
            setOfferDetails(offer);
            setCountdownDate(new Date(offer.VALID_TO));
        }
    }

    const download = async (url, name) => {
        if (!url) {
          throw new Error("Resource URL not provided! You need to provide one");
        }
        const request = await fetch(url);

        const blob = await request.blob();

        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";
        a.download = name
        document.body.appendChild(a);
        a.click();
    };


    return (
        <AnimatedPage>
            <div className='w-100 h-95 bg-wafaa-white-3 overflow-y-scroll pb-5 transition-0-2-bounce' ref={homeSection}>
                <Link to='/home' className='position-absolute top-10 right-10 z-1'>
                    <img src={close_icon} alt='Close icon' />
                </Link>

                <div className={'transition-0-1-bounce position-sticky ' + upperSectionClass}>
                    <img src={offerDetails ? offerDetails.PROMO_IMAGE : ''} alt='Ice cream' className='h-100 w-100 object-fit-cover' />
                    <img src={offerDetails ? offerDetails.PROMO_IMAGE : ''} alt='Ice cream' className={'transition-0-5-bounce height-50 width-50 object-fit-cover position-absolute z-2 bottom-10 rounded-4 right-10 ' + smallImageClass} />
                    <span className='badge bg-wafaa-purple mt-n3 ms-2 d-block z-1 position-relative w-30 px-3 py-2 font-size-10 d-none'>Bonus Offer</span>
                </div>

                <div className='w-90 ps-2 mt-4'>
                    <p className='font-size-13'>
                        <span className='text-wafaa-grey'>Valid until </span>
                        <span className='text-wafaa-black-1'>{moment((offerDetails ? offerDetails.VALID_TO : '')).format('DD/MM/YYYY')}</span>
                    </p>
                    <p className='font-size-20 font-weight-900 text-wafaa-black-1'>
                        {offerDetails ? offerDetails.PROMO_DESC : ''}
                    </p>
                    <p className='text-wafaa-black-1'>
                        {offerDetails ? offerDetails.DESC : ''}
                    </p>
                </div>

                <div className='bg-wafaa-white-2 shadow-wafaa w-95 rounded-3 py-3 px-2 mx-auto'>
                    <div className='d-flex align-items-center align-content-center mb-2'>
                        <span className='text-wafaa-black-1'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 3V1H15V3H9ZM11 14H13V8H11V14ZM12 22C10.7667 22 9.604 21.7623 8.512 21.287C7.42 20.8117 6.466 20.166 5.65 19.35C4.83333 18.5333 4.18733 17.579 3.712 16.487C3.23667 15.395 2.99933 14.2327 3 13C3 11.7667 3.23767 10.604 3.713 9.512C4.18833 8.42 4.834 7.466 5.65 6.65C6.46667 5.83333 7.421 5.18733 8.513 4.712C9.605 4.23667 10.7673 3.99933 12 4C13.0333 4 14.025 4.16667 14.975 4.5C15.925 4.83333 16.8167 5.31667 17.65 5.95L19.05 4.55L20.45 5.95L19.05 7.35C19.6833 8.18333 20.1667 9.075 20.5 10.025C20.8333 10.975 21 11.9667 21 13C21 14.2333 20.7623 15.396 20.287 16.488C19.8117 17.58 19.166 18.534 18.35 19.35C17.5333 20.1667 16.579 20.8127 15.487 21.288C14.395 21.7633 13.2327 22.0007 12 22ZM12 20C13.9333 20 15.5833 19.3167 16.95 17.95C18.3167 16.5833 19 14.9333 19 13C19 11.0667 18.3167 9.41667 16.95 8.05C15.5833 6.68333 13.9333 6 12 6C10.0667 6 8.41667 6.68333 7.05 8.05C5.68333 9.41667 5 11.0667 5 13C5 14.9333 5.68333 16.5833 7.05 17.95C8.41667 19.3167 10.0667 20 12 20Z" fill="currentColor"/>
                        </svg>
                        </span>
                        <p className='font-size-13 text-wafaa-dark-blue-2 font-weight-700 mb-0'>
                            Hurry up! Sales Ends in
                        </p>
                    </div>
                    <div className='d-flex justify-content-between'>

                        <div className='d-flex flex-column w-20'>
                            <div className='d-flex w-100 text-center rounded-1 overflow-hidden border border-1 border-secondary-subtle'>
                                {
                                    days.split("").map((digit, index) =>
                                        (index < (days.split("").length - 1)) ?
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white border border-top-0 border-bottom-0 border-start-0 border-end-1 border-secondary-subtle'>
                                                    {digit}
                                                </div>
                                            )
                                            :
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white'>
                                                    {digit}
                                                </div>
                                            )
                                    )
                                }
                            </div>
                            <div className='font-size-13 text-wafaa-purple w-100 text-center font-weight-700'>
                                DAYS
                            </div>
                        </div>

                        <div className='font-size-25 text-wafaa-purple'>:</div>

                        <div className='d-flex flex-column w-20'>
                            <div className='d-flex w-100 text-center rounded-1 overflow-hidden border border-1 border-secondary-subtle'>
                                {
                                    hours.split("").map((digit, index) =>
                                        (index < (hours.split("").length - 1)) ?
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white border border-top-0 border-bottom-0 border-start-0 border-end-1 border-secondary-subtle'>
                                                    {digit}
                                                </div>
                                            )
                                            :
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white'>
                                                    {digit}
                                                </div>
                                            )
                                    )
                                }
                            </div>
                            <div className='font-size-13 text-wafaa-purple w-100 text-center font-weight-700'>
                                HRS
                            </div>
                        </div>

                        <div className='font-size-25 text-wafaa-purple'>:</div>

                        <div className='d-flex flex-column w-20'>
                            <div className='d-flex w-100 text-center rounded-1 overflow-hidden border border-1 border-secondary-subtle'>
                                {
                                    minutes.split("").map((digit, index) =>
                                        (index < (minutes.split("").length - 1)) ?
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white border border-top-0 border-bottom-0 border-start-0 border-end-1 border-secondary-subtle'>
                                                    {digit}
                                                </div>
                                            )
                                            :
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white'>
                                                    {digit}
                                                </div>
                                            )
                                    )
                                }
                            </div>
                            <div className='font-size-13 text-wafaa-purple w-100 text-center font-weight-700'>
                                MINS
                            </div>
                        </div>

                        <div className='font-size-25 text-wafaa-purple'>:</div>

                        <div className='d-flex flex-column w-20'>
                            <div className='d-flex w-100 text-center rounded-1 overflow-hidden border border-1 border-secondary-subtle'>
                                {
                                    seconds.split("").map((digit, index) =>
                                        (index < (seconds.split("").length - 1)) ?
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white border border-top-0 border-bottom-0 border-start-0 border-end-1 border-secondary-subtle'>
                                                    {digit}
                                                </div>
                                            )
                                            :
                                            (
                                                <div key={index} className='w-50 font-size-25 bg-wafaa-purple text-white'>
                                                    {digit}
                                                </div>
                                            )
                                    )
                                }
                            </div>
                            <div className='font-size-13 text-wafaa-purple w-100 text-center font-weight-700'>
                                SECS
                            </div>
                        </div>

                    </div>

                    <div className='w-100 mt-2 d-none'>
                        <p className='mb-0 text-wafaa-dark-blue font-size-13 font-weight-700'>
                            Only <span className='text-wafaa-danger'>156</span> Items left in stock!
                        </p>
                        <div className="progress height-5 w-70">
                            <div className="progress-bar w-60 bg-wafaa-danger"></div>
                        </div>
                    </div>

                </div>

                <div className='w-90 ms-2 mt-2 d-none'>
                    <p className='font-size-13 text-wafaa-purple font-weight-700'>Conditions apply</p>
                    <div className='w-80 mx-auto text-center d-none'>
                        <p className='font-size-13 font-weight-700 text-wafaa-dark-blue'>Scan the QR code to redeem the offer</p>
                        <img src={qr_code} alt='Qr code' />
                    </div>
                </div>

                <div className='w-90 mx-auto mt-4 mb-3'>
                    {
                        offerDetails != null && offerDetails.FlyerPath !== "" ?

                            <button 
                                onClick={
                                    async () => { 
                                        await download(offerDetails.FlyerPath, offerDetails.FlyerPath.split('/')[offerDetails.FlyerPath.split('/').length - 1]) 
                                        }
                                    } className='bg-white btn btn-white py-2-5 shadow-wafaa-btn text-wafaa-dark-blue w-100'>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 16.077L8.461 12.539L9.169 11.819L11.5 14.15V5.5H12.5V14.15L14.83 11.82L15.539 12.539L12 16.077ZM6.616 19.5C6.15533 19.5 5.771 19.346 5.463 19.038C5.155 18.73 5.00067 18.3453 5 17.884V15.461H6V17.884C6 18.038 6.064 18.1793 6.192 18.308C6.32 18.4367 6.461 18.5007 6.615 18.5H17.385C17.5383 18.5 17.6793 18.436 17.808 18.308C17.9367 18.18 18.0007 18.0387 18 17.884V15.461H19V17.884C19 18.3447 18.846 18.729 18.538 19.037C18.23 19.345 17.8453 19.4993 17.384 19.5H6.616Z" fill="black"/>
                                </svg>
                                Download Flyer
                            </button>
                        : ''
                    }
                </div>

            </div>
            <Navbar activePage='home' />
        </AnimatedPage>
    );
}

export default SingleOffer;
