import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

function Carousel({baseURL, clientID}) {

    const indicator = useRef();

    const [carouselImages, setCarouselImages] = useState([]);

    useEffect(() => {
        getCarouselImages(baseURL);
    },[]);

    async function getCarouselImages() {
        const payLoad = {
            "DATA": {
                "AUTH_KEY" : "TXlDb206TG95QVBJMTIz",
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/GetLoadScreenImage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        if (response.STATUS) {
            const images = response.DATA.map((image) => image.image_name);
            setCarouselImages(images);
            setTimeout(()=>{
                if(indicator.current){
                    indicator.current.click();
                }
            }, 600)
        }
    }

    return (
        <div id="mainPageCarousel" className="carousel slide carousel-fade h-100" data-bs-ride="carousel" data-bs-interval="1300">

            <div className="carousel-indicators">
                {
                    carouselImages.map((image, index) => (
                        index === 0 
                        ? 
                            (
                                <button type="button" data-bs-target="#mainPageCarousel" key={index} data-bs-slide-to={index} className="height-10 width-10 rounded-circle active" ref={indicator} ></button>
                            ) 
                        :
                            (
                                <button type="button" data-bs-target="#mainPageCarousel" key={index} data-bs-slide-to={index} className="height-10 width-10 rounded-circle"></button>
                            )
                    ))
                }
            </div>

            <div className="carousel-inner h-100">
                {
                    carouselImages.map((image, index) => (
                        <div className="carousel-item h-100 active text-center" key={index}>
                            <img src={image} alt="Los Angeles" className="d-block h-100 mx-auto object-fit-cover w-100"/>
                            <div className="carousel-caption mt-n2">
                                <p className="font-weight-700 font-size-20 mb-0">Welcome to True Value</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Carousel;
