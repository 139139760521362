import { Link } from 'react-router-dom';
import qr_code from '../../images/icons/qr_code.svg';
import moment from 'moment';

function OfferBox({promotion}) {
    return (
        <Link to={'/offers/single/' + promotion.PROMO_CODE} className='text-decoration-none'>        
        <div className='width-250 min-width-250 max-width-250 overflow-hidden rounded-4 shadow-wafaa mx-3'>
            <div className='w-100 position-relative'>
                <img src={promotion.PROMO_IMAGE} alt='Offer' className='w-100 height-225 object-fit-cover' />
                <img src={promotion.PROMO_IMAGE} alt='Logo' className='position-absolute bottom-10 right-10 object-fit-cover height-50' />
            </div>
            <span className='badge bg-wafaa-purple mt-n3 ms-2 d-block z-1 position-relative w-35 px-3 py-2 font-size-10'>Bonus Offer</span>
            <p className='font-size-12 w-70 ms-2 mt-2 mb-0 text-wafaa-black-1'>
                { promotion.PROMO_DESC }
            </p>
            <div className='px-2 pb-2 d-flex justify-content-between w-65 align-items-center'>
                <img src={qr_code} alt='QR Code' className='height-30 width-30' />
                <span className='font-size-10 text-wafaa-grey'>Valid until</span>
                <span className='font-size-10 text-wafaa-black-1'>{ moment(promotion.VALID_TO).format('DD/MM/YYYY') }</span>
            </div>
        </div>
        </Link>
    )
}

export default OfferBox;