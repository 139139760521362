import React, { useEffect, useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import Navbar from '../Navbar/Navbar';
import '../../css/fontawesome.css';
import EmojiFeedback from './misc/EmojiFeedback';
import Cookies from 'js-cookie';
import { useAuth } from '../../context/userContext';
import useAlert from '../../hooks/Alert';
import Alert from '../Auth/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useAppSetting } from '../../context/appSettingContext';

function Feedback({ baseURL, clientID }) {
    const [feedbackQuestions, setFeedbackQuestions] = useState([]);

    const { authUser } = useAuth();

    const { appSettings } = useAppSetting();

    const { showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert } = useAlert();

    const navigate = useNavigate();

    const submitFeedbackForm = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        const payLoad = {
            "DATA": {
                "TRAN": [
                ]
            }
        }

        const ratingIDs = formData.getAll('rating_id[]');
        const clientIDs = formData.getAll('client_id[]');
        const ratingNames = formData.getAll('rating_name[]');
        const ratingTypes = formData.getAll('rating_type[]');
        const starsSelected = formData.getAll('star_selected[]');
        const emojisSelected = formData.getAll('emoji_selected[]');
        const otherFeedback = formData.getAll('other_feedback[]');

        for (let index = 0; index < feedbackQuestions.RATINGS.length; index++) {
            const feedback = {
                "client_id": clientIDs[index],
                "rating_id": ratingIDs[index],
                "rating_name": ratingNames[index],
                "option_id": "option1",
                "customer_code": authUser.CUST_CODE,
                "other_feedback": otherFeedback[index]
            };
            if (ratingTypes[index] === 'Star') {
                feedback.option_id = starsSelected[index];
            } else {
                feedback.option_id = emojisSelected[index];
            }
            payLoad.DATA.TRAN.push(feedback);
        }

        const request = await fetch(`${appSettings.AppBaseURL1}Loy_InsertUpdateFeedbackRatings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS !== "FAIL") {
            setAlertStyle({
                background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
            })
            setAlertMessage({
                success: true,
                message: 'Successfully saved',
                mode: 'Feedback'
            });

            setShowAlert('d-block');

            Cookies.set('true-value-feedback', true, { expires: 200 });

            setTimeout(() => {
                navigate('/profile');
            }, 1500);
        } else {
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: response.MESSAGE,
                mode: 'Feedback'
            });
            setShowAlert('d-block');
        }
    }

    useEffect(() => {
        getFeedbackQuestions();
    }, []);

    const getFeedbackQuestions = async () => {
        const payLoad = {
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "client_id": clientID,
                "publish": "Y"
            }
        };
        const request = await fetch(`${appSettings.AppBaseURL1}Loy_getRatingsAndOptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        if (response.STATUS) {
            setFeedbackQuestions(response.DATA);
        }
    }

    return (
        <AnimatedPage>
            <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
            <div className='position-sticky z-2'>
                <div className={'transition-0-5-bounce'}>
                    <PageTitle hideBackButton={false} name='FEEDBACK' />
                </div>
            </div>
            <div className='h-100 bg-wafaa-white-2'>
                <form className='px-3 position-relative overflow-y-scroll pb-5 bg-wafaa-white-2' onSubmit={(event) => submitFeedbackForm(event)}>

                    {
                        feedbackQuestions.RATINGS !== undefined ? feedbackQuestions.RATINGS.map((rating, index) =>
                            <EmojiFeedback key={index} rating={rating} ratingOptions={feedbackQuestions.RATING_OPTIONS} />
                        )
                            :
                            ""
                    }

                    <div className='mb-5 w-100'>
                        <button className='w-90 mx-auto btn bg-wafaa-purple text-white'>
                            Share
                        </button>
                    </div>
                </form>
            </div>

            <Navbar activePage='profile' />
        </AnimatedPage>
    );
}

export default Feedback;
