import { useRef } from "react";
import Cookies from "js-cookie";
import { useAuth } from '../../../context/userContext';

function NameModal({ setCustomerData, customerData, customerName, modalID, setInputHeight, baseURL }) {

    const closeButton = useRef();

    const { setAuthUser } = useAuth();

    async function update(event) {
        event.preventDefault();

        const formData = new FormData(event.target);

        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "LoyUpdateCustomer",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER_UPDATE",
                "CUSTOMER_DETAILS": {
                    "CUST_CODE": customerData.CUST_CODE,
                    "FIRSTNAME": formData.get('first_name'),
                    "LASTNAME": formData.get('last_name'),
                    "MOBILE": customerData.MOBILE,
                    "EMAIL": customerData.EMAIL,
                    "ADDR1": customerData.ADDR1,
                    "ADDR2": customerData.ADDR2,
                    "ADDR3": customerData.ADDR3,
                    "BIRTHDATE": customerData.BIRTH_DATE,
                    "COMPNAME": customerData.COMP_NAME,
                    "NATIONALITY": customerData.NATIONALITY,
                    "GENDER": customerData.GENDER,
                    "country_code": customerData.country_code,
                    "city_code": customerData.city_code,
                    "area_code": customerData.area_code,
                    "CITY": customerData.CITY,
                    "AREA": customerData.AREA,
                    "COUNTRY": customerData.COUNTRY,
                }
            }
        }

        const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        })

        const response = await request.json();

        if (response.STATUS) {
            const newCustomerData = customerData;

            newCustomerData.CUST_NAME = formData.get('first_name') + ' ' + formData.get('last_name');

            const userCookie = Cookies.get('wafaa-user-data');

            const userData = JSON.parse(userCookie);

            userData.CUST_NAME = newCustomerData.CUST_NAME;

            Cookies.set('wafaa-user-data', JSON.stringify(userData), { expires: 30 });

            setCustomerData({ ...newCustomerData });

            setAuthUser(userData);

            closeButton.current.click();
        } else {
            closeButton.current.click();
            alert("Failed to update");
        }

    }

    return (
        <>
            <div className="modal fade max-width-700 start-50" style={{
                transform: "translateX(-50%)"
            }} id={modalID} aria-hidden="true" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen max-width-700">
                    <div className="modal-content">
                        <div className="modal-header bg-wafaa-white-2 shadow">
                            <h5 className="modal-title text-wafaa-black-1">Change Name</h5>
                            <button type="button" className="text-wafaa-black-1 bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close" style={{
                                transform:"scale(1.8)"
                            }} ref={closeButton}>&times;</button>
                        </div>
                        <form onSubmit={(event) => { update(event) }} className="h-100 d-flex flex-column">
                            <div className="modal-body bg-wafaa-white-3">
                                <div className='form-group position-relative mt-3'>
                                    <label className='text-wafaa-grey position-absolute bg-wafaa-white-2 z-0'>First Name*</label>
                                    <input
                                        className=
                                        {
                                            'font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1 text-wafaa-black-1 ' +
                                            (customerName.split(' ')[0] !== '' ? 'mt-4' : '')
                                        }
                                        onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='text' name='first_name' defaultValue={customerName.split(' ')[0]} />
                                </div>
                                <div className='form-group position-relative mt-3'>
                                    <label className='text-wafaa-grey position-absolute bg-wafaa-white-2 z-0'>Last Name*</label>
                                    <input
                                        className=
                                        {
                                            'font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1 text-wafaa-black-1 ' +
                                            (customerName.split(' ')[1] !== '' ? 'mt-4' : '')
                                        }
                                        onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='text' name='last_name' defaultValue={customerName.split(' ')[1]} />
                                </div>
                            </div>
                            <div className="modal-footer bg-wafaa-white-2">
                                <button className='w-100 btn btn-lg py-2-5 btn-primary bg-wafaa-purple-2 border-wafaa-purple shadow-wafaa-btn font-size-12'>
                                    DONE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NameModal;