import React, { useEffect, useRef, useState } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import moment from 'moment';
import { useAuth } from '../../context/userContext';
import NameModal from './misc/NameModal';
import GenderModal from './misc/GenderModal';
import DateModal from './misc/DateModal';
import NationalityModal from './misc/NationalityModal';
import MobileNumberModal from './misc/MobileNumberModal';
import EmailModal from './misc/EmailModal';
import CountryModal from './misc/CountryModal';
import AddressModal from './misc/AddressModal';
import Cookies from "js-cookie";
import CityModal from './misc/CityModal';
import AreaModal from './misc/AreaModal';

function MyProfile({ baseURL, clientID, appSettings }) {

    const homeSection = useRef();

    const fileInput = useRef();

    const [bigDashClass, setBigDashClass] = useState('height-100');

    const [profileFlexClass, setProfileFlexClass] = useState('flex-column');

    const [titleClass, setTitleClass] = useState('mt-0');

    const [customerData, setCustomerData] = useState({});

    const [fileName, setFileName] = useState('');

    const { authUser, setAuthUser } = useAuth();


    useEffect(() => {
        (async () => {                        
            if (authUser) {
                await fetchCustomerData();
            }
        })()

    }, [authUser]);

    async function fetchCustomerData() {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER",
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setCustomerData(response);

    }

    function setInputHeight(event) {
        if (event.type === 'focus') {
            event.target.classList.add('mt-4');
        }
        if (event.type === 'blur') {
            if (event.target.value.trim() === '') {
                event.target.classList.remove('mt-4');
            }
        }
    }

    async function updateFile(event) {
        const file = event.target.files[0];

        const formData = new FormData();

        if (file && file.type.startsWith('image/')) {

            setFileName(file.name);
            formData.append('myFile', file);
            const request = await fetch(`${baseURL}/ProfilePictureUploader`, {
                method: 'POST',
                body: formData
            })

            const response = await request.json();
            if (response.Success === "True") {
                const filePath = response.fileName;
                const fileName = filePath.split('/')[filePath.split('/').length - 1];

                const payLoad = {
                    "DIV_DES": "",
                    "DIV_ID": "1",
                    "FUNCTION": "LoyUpdateCustomer",
                    "SEND_KEY": "123456",
                    "DATA": {
                        "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                        "TYPE_NAME": "CUSTOMER_UPDATE",
                        "CUSTOMER_DETAILS": {
                            "CUST_CODE": customerData.CUST_CODE,
                            "FIRSTNAME": customerData.CUST_NAME.split(' ')[0],
                            "LASTNAME": customerData.CUST_NAME.split(' ')[1],
                            "MOBILE": customerData.MOBILE,
                            "EMAIL": customerData.EMAIL,
                            "ADDR1": customerData.ADDR1,
                            "ADDR2": customerData.ADDR2,
                            "ADDR3": customerData.ADDR3,
                            "BIRTHDATE": customerData.BIRTH_DATE,
                            "COMPNAME": customerData.COMP_NAME,
                            "NATIONALITY": customerData.NATIONALITY,
                            "COUNTRY": customerData.COUNTRY,
                            "GENDER": customerData.GENDER,
                            "profile_img": fileName,
                        },
                        "CLIENT_ID": clientID
                    }
                }

                const request = await fetch(`${baseURL}/LoyUpdateCustomer`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payLoad)
                })

                const updateResponse = await request.json();

                if (updateResponse.STATUS) {
                    customerData.PROFILE_IMG = fileName;
                    setCustomerData({ ...customerData });
                    alert("Updated successfully");
                    await fetchCustomerData();

                    setFileName('');
                } else {
                    alert("Failed to update");
                }
            } else {
                alert('Failed to upload');
            }

        } else {
            alert("You need to place an image file type");
        }


    }

    if (customerData.COUNTRY !== undefined) { // any property to be present for the render
        return (
            <AnimatedPage>
                <div className='position-sticky z-2'>
                    <div className={'transition-0-5-bounce ' + bigDashClass}>
                        <PageTitle>
                            <div className={'transition-0-5-ease d-flex justify-content-center align-items-center ' + titleClass}>
                                <div className={'transition-0-5-ease align-items-center text-white px-0 d-flex justify-content-between ' + profileFlexClass}>
                                    <img src={customerData ? customerData.PROFILE_IMAGE : ''} alt='Profile pic' className='height-60 width-60 rounded-circle border border-3 border-wafaa-purple object-fit-cover' />
                                    <p className='mb-0 font-size-22 font-weight-700'>{customerData ? customerData.CUST_NAME : ''}</p>
                                </div>
                            </div>
                        </PageTitle>
                    </div>
                </div>
    
    
                <div className='w-100 overflow-y-scroll pb-5 transition-0-5-bounce bg-wafaa-white-3 mt-5 pt-5 pb-5' ref={homeSection}>
    
                    <div className='w-95 mx-auto px-2'>
                        <p className='mb-0 font-size-14 font-weight-500 text-wafaa-black-1'>PERSONAL DETAILS</p>
                    </div>
    
                    <div className='shadow bg-wafaa-white-2  mx-3 my-3 rounded-2 py-1'>
                        <p className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <input type='file' id='file' className='d-none' ref={fileInput} onChange={(event) => updateFile(event)} name='myFile' />
                            <label htmlFor='file' className='w-100 d-flex'>
                                <span className='col text-wafaa-black-1'>Change profile photo</span>
                                <span className='col text-truncate text-end'>{fileName}</span>
                            </label>
                        </p>
    
                        <p data-bs-toggle="modal" href="#nameModal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Full Name</span>
                            <span className='text-wafaa-purple'>
                                {customerData.CUST_NAME ? customerData.CUST_NAME : ''}
                            </span>
                        </p>
                        <NameModal
                            customerName={customerData.CUST_NAME ? customerData.CUST_NAME : ''}
                            key={'namemodal'}
                            modalID={'nameModal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
    
                        <p data-bs-toggle="modal" href="#genderModal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Gender</span>
                            <span className='text-wafaa-purple'>
                                {customerData.GENDER ? customerData.GENDER : ''}
                            </span>
                        </p>
                        <GenderModal
                            customerGender={customerData.GENDER ? customerData.GENDER : ''}
                            key={'gendermodal'}
                            modalID={'genderModal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
    
                        <p data-bs-toggle="modal" href="#birthdatemodal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Birthday</span>
                            <span className='text-wafaa-purple'>
                                {customerData.BIRTH_DATE ? moment(customerData.BIRTH_DATE).format('DD.MM.YYYY') : ''}
                            </span>
                        </p>
                        <DateModal
                            customerBirthDate={customerData.BIRTH_DATE ? moment(customerData.BIRTH_DATE).format('DD.MM.YYYY') : ''}
                            key={'birthdatemodal'}
                            modalID={'birthdatemodal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
    
                        <p data-bs-toggle="modal" href="#nationalitymodal" className='w-100 mx-auto border border-1 border-top-0 border-bottom-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Nationality</span>
                            <span className='text-wafaa-purple'>
                                {customerData.NATIONALITY ? customerData.NATIONALITY.trim() : ''}
                            </span>
                        </p>
    
                        <NationalityModal
                            customerNationality={customerData.NATIONALITY ? customerData.NATIONALITY.trim() : ''}
                            key={'nationalitymodal'}
                            modalID={'nationalitymodal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
                    </div>
    
                    <div className='w-95 mx-auto px-2'>
                        <p className='mb-0 font-size-14 font-weight-500 text-wafaa-black-1'>SECURITY</p>
                    </div>
    
                    <div className='shadow bg-wafaa-white-2 mx-3 my-3 rounded-2 py-1'>
                        <p className='w-100 mx-auto border border-1 border-top-0 border-bottom-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Change password</span>
                        </p>
                    </div>
    
                    <div className='w-95 mx-auto px-2'>
                        <p className='mb-0 font-size-14 font-weight-500 text-wafaa-black-1'>CONTACT AND COUNTRY INFOMATION</p>
                    </div>
    
                    <div className='shadow bg-wafaa-white-2 mx-3 my-3 rounded-2 py-1'>
                        <p data-bs-toggle="modal" href="#mobilenumbermodal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Mobile Number</span>
                            <span className='text-wafaa-purple'>
                                {customerData.MOBILE ? customerData.MOBILE : ''}
                            </span>
                        </p>
                        <MobileNumberModal
                            customerMobile={customerData.MOBILE ? customerData.MOBILE : ''}
                            key={'mobilenumbermodal'}
                            modalID={'mobilenumbermodal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
    
                        <p data-bs-toggle="modal" href="#emailmodal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Email</span>
                            <span className='text-wafaa-purple'>
                                {customerData.EMAIL ? customerData.EMAIL : ''}
                            </span>
                        </p>
                        <EmailModal
                            customerEmail={customerData.EMAIL ? customerData.EMAIL : ''}
                            key={'emailmodal'}
                            modalID={'emailmodal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
    
                        <p data-bs-toggle="modal" href="#countrymodal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Country</span>
                            <span className='text-wafaa-purple'>
                                {customerData.COUNTRY ? customerData.COUNTRY : 'Not chosen'}
                            </span>
                        </p>
                        <CountryModal
                            customerCountry={customerData.COUNTRY ? customerData.COUNTRY : ''}
                            key={'countrymodal'}
                            modalID={'countrymodal'}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                            appSettings={appSettings}
                        />
                        
                        <p data-bs-toggle="modal" href="#citymodal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>City</span>
                            <span className='text-wafaa-purple'>
                                {customerData.CITY ? customerData.CITY : 'Not chosen'}
                            </span>
                        </p>
                        <CityModal
                            customerCountry={customerData.CITY ? customerData.CITY : ''}
                            key={'citymodal'}
                            modalID={'citymodal'}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                            appSettings={appSettings}
                        />
    
                        <p data-bs-toggle="modal" href="#areamodal" className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Area</span>
                            <span className='text-wafaa-purple'>
                                {customerData.AREA ? customerData.AREA : 'Not chosen'}
                            </span>
                        </p>
                        <AreaModal
                            customerCountry={customerData.COUNTRY ? customerData.COUNTRY : ''}
                            key={'areamodal'}
                            modalID={'areamodal'}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                            appSettings={appSettings}
                        />
    
                        <p className='w-100 mx-auto border border-1 border-top-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Currency</span>
                            <span className='text-wafaa-purple'>UAE Dirham</span>
                        </p>
    
                        <p data-bs-toggle="modal" href="#addressmodal" className='w-100 mx-auto border border-1 border-top-0 border-bottom-0 border-end-0 border-start-0 p-2 mb-0 font-size-14 d-flex justify-content-between font-weight-500'>
                            <span className='text-wafaa-black-1'>Address</span>
                            <span className='text-wafaa-grey'>
                                &gt;
                            </span>
                        </p>
                        <AddressModal
                            customerAddresses={[customerData.ADDR1, customerData.ADDR2, customerData.ADDR3]}
                            key={'addressmodal'}
                            modalID={'addressmodal'}
                            setInputHeight={setInputHeight}
                            customerData={customerData}
                            setCustomerData={setCustomerData}
                            baseURL={baseURL}
                        />
                    </div>
                </div>
            </AnimatedPage>
        );
    }else{
        return (
            <AnimatedPage>
                <div></div>
            </AnimatedPage>
        )
    }
   
}

export default MyProfile;
