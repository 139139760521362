import { useState, useEffect, useRef } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import PageTitle from '../Misc/PageTitle';
import { useAuth } from '../../context/userContext';
import moment from 'moment';
import subtract from '../../images/icons/subtract.svg';
import JsBarcode from 'jsbarcode';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import useAlert from '../../hooks/Alert';
import Alert from '../Auth/Alert/Alert';
import QrReader from './QrReader';

function Voucher({ baseURL, clientID }) {
    const generatedVoucherBtn = useRef();

    const addVoucherBtn = useRef();

    const closeCreateVoucher = useRef();

    const { authUser } = useAuth();

    const [menu, setMenu] = useState('new');

    const [newVouchers, setNewVouchers] = useState([]);

    const [usedVouchers, setUsedVouchers] = useState([]);

    const [expiredVouchers, setExpiredVouchers] = useState([]);

    const [createdVoucher, setCreatedVoucher] = useState({});

    const [pointsToRedeem, setPointsToRedeem] = useState(0);

    const [toggleQrReader, setToggleQrReader] = useState(false);
    
    const [scannedResult, setScannedResult] = useState("");

    const [code, setCode] = useState("");

    const [calculatedPoints, setCalculatedPoints] = useState(0);
    
    const [customerData, setCustomerData] = useState({});

    const { showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert } = useAlert();

    function switchMenu(menu) {
        setMenu(menu);
    }

    const handleInput = (event) => {
        const { value, maxLength } = event.target;
        setPointsToRedeem(value.slice(0, maxLength));
    }

    const handlePoints = (number) => {
        const result = parseFloat(number) + parseFloat(pointsToRedeem);
        setPointsToRedeem(result);
    }

    useEffect(() => {
        if (authUser) {
            getCustomerData();
            getNewVouchers();
            getUsedVouchers();
            getExpiredVouchers();
        }
    }, [authUser]);

    useEffect(() => {
        if (createdVoucher.VOUCHER_NO) {
            JsBarcode('.js-bar-code', (createdVoucher.VOUCHER_NO));
        }
    }, [createdVoucher])

    const getCustomerData = async () => {
        const payLoad = {
            "DIV_DES": "",
            "DIV_ID": "1",
            "FUNCTION": "GetLoyPoints",
            "SEND_KEY": "123456",
            "DATA": {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "TYPE_NAME": "CUSTOMER",
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "CLIENT_ID": clientID
            }
        };
        const request = await fetch(`${baseURL}/GetLoyPoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        setCustomerData(response);
    }

    useEffect(() => {
        if (pointsToRedeem > 0) {
            if (pointsToRedeem > customerData.POINTS_TOTAL) {
                alert('Insufficient points');
            } else {
                getCalculatedPoints();
            }
        } else {
            setCalculatedPoints(0)
        }
    }, [pointsToRedeem]);

    const getCalculatedPoints = async () => {
        const payLoad = {
            DATA: {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "TYPE_NAME": "CAL_POINTS",
                "POINTS": pointsToRedeem,
                "CLIENT_ID": clientID
            }
        }
        const request = await fetch(`${baseURL}/CalculatePoints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();
        if (response.STATUS) {
            setCalculatedPoints(response.REEDEEM_AMOUNT);
        } else {
            console.error(response.MESSAGE);
        }
    }

    const getNewVouchers = async () => {
        const payLoad = {
            DATA: {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "TYPE_NAME": "VOUCHER_HISTORY",
                "USED": 0
            }
        }
        const request = await fetch(`${baseURL}/GetVoucher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            setNewVouchers(response.VOUCHER_HEADER);
        } else {
            console.error('Failed to get new vouchers')
        }
    }

    const getUsedVouchers = async () => {
        const payLoad = {
            DATA: {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "TYPE_NAME": "VOUCHER_HISTORY",
                "USED": 1,
                "CLIENT_ID": clientID
            }
        }
        const request = await fetch(`${baseURL}/GetVoucher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            setUsedVouchers(response.VOUCHER_HEADER);
        } else {
            console.error('Failed to get used vouchers')
        }
    }

    const getExpiredVouchers = async () => {
        const payLoad = {
            DATA: {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "TYPE_NAME": "VOUCHER_HISTORY",
                "EXPIRED": 1
            }
        }
        const request = await fetch(`${baseURL}/GetVoucher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            setExpiredVouchers(response.VOUCHER_HEADER);
        } else {
            console.error('Failed to get used vouchers')
        }
    }

    const createVoucher = async () => {
        if (pointsToRedeem > customerData.POINTS_TOTAL) {
            alert(`Only possible to share ${customerData.POINTS_TOTAL}`);
            return;
        }
        const payLoad = {
            DATA: {
                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "TYPE_NAME": "NEW_VOUCHER",
                "POINTS": pointsToRedeem,
                "CLIENT_ID": clientID
            }
        }
        const request = await fetch(`${baseURL}/CreateVoucher`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            setCreatedVoucher(response);
            closeCreateVoucher.current.click();

            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
            })
            setAlertMessage({
                success: true,
                message: "Successfully created voucher",
                mode: 'Voucher'
            });
            setShowAlert('d-block');

            generatedVoucherBtn.current.click();

            await getNewVouchers();
        } else {

            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: "Failed to create a new voucher",
                mode: 'Voucher'
            });
            setShowAlert('d-block');
            closeCreateVoucher.current.click();
            console.error('Failed to create a new voucher')
        }
    }

    const handleGetCodeSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        const payLoad = {
            "DATA": {
                "TYPE_NAME": "NEW_VOUCHER",
                "ENTRY_NO": formData.get('code'),
                "MOBILE_NUM": authUser ? authUser.MOBILE : '',
                "CUST_CODE": authUser ? authUser.CUST_CODE : '',
            }
        }

        const request = await fetch(`${baseURL}/LoyAddVoucherExternal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        });

        const response = await request.json();

        if (response.STATUS) {
            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
            })
            setAlertMessage({
                success: true,
                message: "Voucher added successfully",
                mode: 'Voucher'
            });
            setShowAlert('d-block');

            await getNewVouchers();
        } else {
            hideAlert()
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: "Voucher code does not exist",
                mode: 'Voucher'
            });
            setShowAlert('d-block');
            console.error('Failed to create a new voucher')
        }
        setToggleQrReader(false);
        setCode('');
    }

    useEffect(() => {
        setCode(scannedResult);
        setTimeout(() => {
            addVoucherBtn.current.click();
        }, 1500);
    }, [scannedResult])

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    }

    return (
        <AnimatedPage>
            <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
            <div className='position-sticky z-2'>
                <PageTitle name='VOUCHER' />
            </div>


            <div className='w-100 h-80 overflow-y-scroll pb-5 transition-0-5-bounce bg-wafaa-white-2'>

                <div className='w-90 shadow bg-wafaa-white-3 mx-auto my-2 rounded-3' data-bs-toggle="modal" data-bs-target="#getCode">
                    <div className='row mx-0 p-3'>
                        <div className='col-3'>
                            <div className='align-content-center align-items-center d-flex fill-wafaa-purple-path h-100 justify-content-center text-wafaa-purple w-100 text-dark-blue-2' style={{
                                transform: 'rotate(-45deg)'
                            }}>
                                <svg className='width-40 height-40' viewBox="0 0 200 200" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M123.333 66.6666L133.333 76.6666L76.6666 133.333L66.6666 123.333L123.333 66.6666ZM33.3333 33.3333H166.667C175.917 33.3333 183.333 40.7499 183.333 49.9999V83.3333C178.913 83.3333 174.674 85.0892 171.548 88.2148C168.423 91.3404 166.667 95.5796 166.667 99.9999C166.667 104.42 168.423 108.659 171.548 111.785C174.674 114.911 178.913 116.667 183.333 116.667V150C183.333 159.25 175.917 166.667 166.667 166.667H33.3333C28.913 166.667 24.6738 164.911 21.5482 161.785C18.4226 158.659 16.6666 154.42 16.6666 150V116.667C25.9166 116.667 33.3333 109.25 33.3333 99.9999C33.3333 95.5796 31.5773 91.3404 28.4517 88.2148C25.3261 85.0892 21.0869 83.3333 16.6666 83.3333V49.9999C16.6666 45.5796 18.4226 41.3404 21.5482 38.2148C24.6738 35.0892 28.913 33.3333 33.3333 33.3333ZM33.3333 49.9999V71.1666C38.3973 74.0866 42.6029 78.2888 45.5271 83.3503C48.4512 88.4119 49.9908 94.1544 49.9908 99.9999C49.9908 105.845 48.4512 111.588 45.5271 116.649C42.6029 121.711 38.3973 125.913 33.3333 128.833V150H166.667V128.833C161.603 125.913 157.397 121.711 154.473 116.649C151.549 111.588 150.009 105.845 150.009 99.9999C150.009 94.1544 151.549 88.4119 154.473 83.3503C157.397 78.2888 161.603 74.0866 166.667 71.1666V49.9999H33.3333ZM79.1666 66.6666C86.0833 66.6666 91.6666 72.2499 91.6666 79.1666C91.6666 86.0832 86.0833 91.6666 79.1666 91.6666C72.25 91.6666 66.6666 86.0832 66.6666 79.1666C66.6666 72.2499 72.25 66.6666 79.1666 66.6666ZM120.833 108.333C127.75 108.333 133.333 113.917 133.333 120.833C133.333 127.75 127.75 133.333 120.833 133.333C113.917 133.333 108.333 127.75 108.333 120.833C108.333 113.917 113.917 108.333 120.833 108.333Z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>
                        <div className='col-9'>
                            <p className='font-size-15 font-weight-600 mb-0 text-wafaa-dark-blue-2'>Got a code?</p>
                            <p className='font-size-12 mb-0 text-wafaa-dark-blue-2'>Add your code and save</p>
                        </div>
                    </div>
                </div>

                <div className="modal fade max-width-700 start-50" style={{
                    transform: "translateX(-50%)"
                }} id="getCode" tabIndex="-1" aria-hidden="true">
                    <div className="h-auto modal-dialog max-width-700 w-100 position-absolute bottom-0 m-0">
                        <div className="bg-wafaa-white-2 modal-content">

                            <div className="modal-header border-0 pb-0">
                                <button data-dismiss="modal" type="button" className="align-items-center bg-transparent text-dark btn d-flex font-size-25 height-30 justify-content-center ms-auto fa-3x rounded-circle width-30" data-bs-dismiss="modal" aria-label="Close" onClick={() => setToggleQrReader(false)}>&times;</button>
                            </div>
                            <div className="modal-body pt-0">
                                <div className={toggleQrReader ? 'd-block' : 'd-none'}>
                                    {toggleQrReader ? <QrReader setScannedResult={setScannedResult}/> : 'd-none'}  
                                </div>
                                <div className='row mx-0 p-3 ps-0'>
                                    <div className='col-2 ps-0'>
                                        <div className='align-content-center align-items-center d-flex fill-wafaa-purple-path h-100 justify-content-center text-wafaa-purple w-100' style={{
                                            transform: 'rotate(-45deg)'
                                        }}>
                                            <svg className='width-40 height-40' viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M123.333 66.6666L133.333 76.6666L76.6666 133.333L66.6666 123.333L123.333 66.6666ZM33.3333 33.3333H166.667C175.917 33.3333 183.333 40.7499 183.333 49.9999V83.3333C178.913 83.3333 174.674 85.0892 171.548 88.2148C168.423 91.3404 166.667 95.5796 166.667 99.9999C166.667 104.42 168.423 108.659 171.548 111.785C174.674 114.911 178.913 116.667 183.333 116.667V150C183.333 159.25 175.917 166.667 166.667 166.667H33.3333C28.913 166.667 24.6738 164.911 21.5482 161.785C18.4226 158.659 16.6666 154.42 16.6666 150V116.667C25.9166 116.667 33.3333 109.25 33.3333 99.9999C33.3333 95.5796 31.5773 91.3404 28.4517 88.2148C25.3261 85.0892 21.0869 83.3333 16.6666 83.3333V49.9999C16.6666 45.5796 18.4226 41.3404 21.5482 38.2148C24.6738 35.0892 28.913 33.3333 33.3333 33.3333ZM33.3333 49.9999V71.1666C38.3973 74.0866 42.6029 78.2888 45.5271 83.3503C48.4512 88.4119 49.9908 94.1544 49.9908 99.9999C49.9908 105.845 48.4512 111.588 45.5271 116.649C42.6029 121.711 38.3973 125.913 33.3333 128.833V150H166.667V128.833C161.603 125.913 157.397 121.711 154.473 116.649C151.549 111.588 150.009 105.845 150.009 99.9999C150.009 94.1544 151.549 88.4119 154.473 83.3503C157.397 78.2888 161.603 74.0866 166.667 71.1666V49.9999H33.3333ZM79.1666 66.6666C86.0833 66.6666 91.6666 72.2499 91.6666 79.1666C91.6666 86.0832 86.0833 91.6666 79.1666 91.6666C72.25 91.6666 66.6666 86.0832 66.6666 79.1666C66.6666 72.2499 72.25 66.6666 79.1666 66.6666ZM120.833 108.333C127.75 108.333 133.333 113.917 133.333 120.833C133.333 127.75 127.75 133.333 120.833 133.333C113.917 133.333 108.333 127.75 108.333 120.833C108.333 113.917 113.917 108.333 120.833 108.333Z" fill="#F1F1F1" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='col-9'>
                                        <p className='font-size-15 font-weight-600 mb-0 text-wafaa-dark-blue-2'>Save on your order now!</p>
                                        <p className='font-size-12 mb-0 text-wafaa-dark-blue-2'>Add your code and save</p>
                                    </div>
                                    <div className='border border-wafaa-purple col-1 rounded-3 p-1 text-wafaa-black-1' onClick={() => setToggleQrReader(!toggleQrReader)}>
                                        <svg className='w-100 h-100 object-fit-cover' viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.5 20V18H18.5V15H20.5V18.5C20.5 18.9 20.3 19.2 20 19.5C19.7 19.8 19.3 20 19 20H15.5ZM5.5 20H2C1.6 20 1.3 19.8 1 19.5C0.7 19.2 0.5 18.8 0.5 18.5V15H2.5V18H5.5V20ZM15.5 0H19C19.4 0 19.7 0.2 20 0.5C20.3 0.8 20.5 1.1 20.5 1.5V5H18.5V2H15.5V0ZM5.5 0V2H2.5V5H0.5V1.5C0.5 1.1 0.7 0.8 1 0.5C1.3 0.2 1.6 0 2 0H5.5ZM17.5 9H3.5V11H17.5V9Z" fill="currentColor"/>
                                        </svg>
                                    </div>
                                </div>

                                <div>
                                    <form onSubmit={(event) => handleGetCodeSubmit(event)}>

                                        <div className='form-group'>
                                            <input className='bg-transparent text-wafaa-black-1 border border-secondary p-2 w-100 rounded-2' placeholder='Enter code' name='code' required value={code} onChange={(event) => handleCodeChange(event)} />
                                        </div>

                                        <div>
                                            <button type="submit" data-bs-dismiss="modal" className="bg-wafaa-purple btn text-white w-100 mt-3" ref={addVoucherBtn}>
                                                Add Voucher
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="btn-group w-90 d-flex mx-auto mb-2 mt-4" data-toggle="buttons" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                    <label className="btn btn-wafaa-secondary text-wafaa-grey-2 font-size-16" htmlFor="btnradio1" onClick={() => { switchMenu('new') }}>New</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                    <label className="btn btn-wafaa-secondary text-wafaa-grey-2 font-size-16" htmlFor="btnradio2" onClick={() => { switchMenu('used') }}>Used</label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                    <label className="btn btn-wafaa-secondary text-wafaa-grey-2 font-size-16" htmlFor="btnradio3" onClick={() => { switchMenu('expired') }}>Expired</label>
                </div>

                <div className='mt-3'>
                    {
                        menu === "new" ?
                            (
                                (newVouchers.length > 0)
                                    ?
                                    <div>
                                        {
                                            newVouchers.map((voucher, index) =>
                                                <Link key={index} to='/vouchers/single' state={voucher} className='text-decoration-none'>
                                                    <div className='height-100 bg-white col-11 d-flex mb-4 mx-auto overflow-hidden rounded-end-2 rounded-end-3 shadow-sm'>
                                                        <div className='align-items-center col-9 d-flex flex-column justify-content-center text-center'>
                                                            <p className='font-size-20 font-weight-700 text-wafaa-dark-blue mb-0'>VOUCHER</p>
                                                            <p className='text-wafaa-grey mb-0 font-size-12'>
                                                                Valid until
                                                                <span className='ms-1 font-weight-700'>{moment(voucher.Valid_Until).format('DD MMMM YYYY')}</span>

                                                            </p>
                                                        </div>
                                                        <div className='d-flex justify-content-center align-items-center bg-warning col-3 m-0 text-center px-2 py-4'>
                                                            <span className='d-inline-block font-size-15 px-2 text-center text-white font-weight-700' style={{
                                                                transform: 'rotate(270deg)'
                                                            }}>
                                                                AED {voucher.Redeem_value}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    </div>
                                    :
                                    <div className='text-center text-wafaa-dark-blue-2'>
                                        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M123.333 66.6666L133.333 76.6666L76.6666 133.333L66.6666 123.333L123.333 66.6666ZM33.3333 33.3333H166.667C175.917 33.3333 183.333 40.7499 183.333 49.9999V83.3333C178.913 83.3333 174.674 85.0892 171.548 88.2148C168.423 91.3404 166.667 95.5796 166.667 99.9999C166.667 104.42 168.423 108.659 171.548 111.785C174.674 114.911 178.913 116.667 183.333 116.667V150C183.333 159.25 175.917 166.667 166.667 166.667H33.3333C28.913 166.667 24.6738 164.911 21.5482 161.785C18.4226 158.659 16.6666 154.42 16.6666 150V116.667C25.9166 116.667 33.3333 109.25 33.3333 99.9999C33.3333 95.5796 31.5773 91.3404 28.4517 88.2148C25.3261 85.0892 21.0869 83.3333 16.6666 83.3333V49.9999C16.6666 45.5796 18.4226 41.3404 21.5482 38.2148C24.6738 35.0892 28.913 33.3333 33.3333 33.3333ZM33.3333 49.9999V71.1666C38.3973 74.0866 42.6029 78.2888 45.5271 83.3503C48.4512 88.4119 49.9908 94.1544 49.9908 99.9999C49.9908 105.845 48.4512 111.588 45.5271 116.649C42.6029 121.711 38.3973 125.913 33.3333 128.833V150H166.667V128.833C161.603 125.913 157.397 121.711 154.473 116.649C151.549 111.588 150.009 105.845 150.009 99.9999C150.009 94.1544 151.549 88.4119 154.473 83.3503C157.397 78.2888 161.603 74.0866 166.667 71.1666V49.9999H33.3333ZM79.1666 66.6666C86.0833 66.6666 91.6666 72.2499 91.6666 79.1666C91.6666 86.0832 86.0833 91.6666 79.1666 91.6666C72.25 91.6666 66.6666 86.0832 66.6666 79.1666C66.6666 72.2499 72.25 66.6666 79.1666 66.6666ZM120.833 108.333C127.75 108.333 133.333 113.917 133.333 120.833C133.333 127.75 127.75 133.333 120.833 133.333C113.917 133.333 108.333 127.75 108.333 120.833C108.333 113.917 113.917 108.333 120.833 108.333Z" fill="#F1F1F1" />
                                        </svg>
                                        <p className='font-weight-800'>No vouchers found</p>
                                        <p className='font-size-12'>To create a new voucher select 'Create Voucher'</p>
                                    </div>

                            )
                            :
                            menu === "used" ?
                            (
                                (usedVouchers.length > 0)
                                    ?
                                    <div>
                                        {
                                            usedVouchers.map((voucher, index) =>
                                                <div className='height-100 bg-white col-11 d-flex mb-4 mx-auto overflow-hidden rounded-end-2 rounded-end-3 shadow-sm'>
                                                    <div className='align-items-center col-9 d-flex flex-column justify-content-center text-center'>
                                                        <p className='font-size-20 font-weight-700 text-wafaa-dark-blue mb-0'>VOUCHER</p>
                                                        <p className='text-wafaa-grey mb-0 font-size-12'>
                                                            Redeemed on
                                                            <span className='ms-1 font-weight-700'>{moment(voucher.Valid_Until).format('DD MMMM YYYY')}</span>
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-center align-items-center bg-warning col-3 m-0 text-center px-2 py-4'>
                                                        <span className='d-inline-block font-size-15 px-2 text-center text-white font-weight-700' style={{
                                                            transform: 'rotate(270deg)'
                                                        }}>
                                                            AED {voucher.Redeem_value}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    :
                                    <div className='text-center text-wafaa-dark-blue-2'>
                                        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M123.333 66.6666L133.333 76.6666L76.6666 133.333L66.6666 123.333L123.333 66.6666ZM33.3333 33.3333H166.667C175.917 33.3333 183.333 40.7499 183.333 49.9999V83.3333C178.913 83.3333 174.674 85.0892 171.548 88.2148C168.423 91.3404 166.667 95.5796 166.667 99.9999C166.667 104.42 168.423 108.659 171.548 111.785C174.674 114.911 178.913 116.667 183.333 116.667V150C183.333 159.25 175.917 166.667 166.667 166.667H33.3333C28.913 166.667 24.6738 164.911 21.5482 161.785C18.4226 158.659 16.6666 154.42 16.6666 150V116.667C25.9166 116.667 33.3333 109.25 33.3333 99.9999C33.3333 95.5796 31.5773 91.3404 28.4517 88.2148C25.3261 85.0892 21.0869 83.3333 16.6666 83.3333V49.9999C16.6666 45.5796 18.4226 41.3404 21.5482 38.2148C24.6738 35.0892 28.913 33.3333 33.3333 33.3333ZM33.3333 49.9999V71.1666C38.3973 74.0866 42.6029 78.2888 45.5271 83.3503C48.4512 88.4119 49.9908 94.1544 49.9908 99.9999C49.9908 105.845 48.4512 111.588 45.5271 116.649C42.6029 121.711 38.3973 125.913 33.3333 128.833V150H166.667V128.833C161.603 125.913 157.397 121.711 154.473 116.649C151.549 111.588 150.009 105.845 150.009 99.9999C150.009 94.1544 151.549 88.4119 154.473 83.3503C157.397 78.2888 161.603 74.0866 166.667 71.1666V49.9999H33.3333ZM79.1666 66.6666C86.0833 66.6666 91.6666 72.2499 91.6666 79.1666C91.6666 86.0832 86.0833 91.6666 79.1666 91.6666C72.25 91.6666 66.6666 86.0832 66.6666 79.1666C66.6666 72.2499 72.25 66.6666 79.1666 66.6666ZM120.833 108.333C127.75 108.333 133.333 113.917 133.333 120.833C133.333 127.75 127.75 133.333 120.833 133.333C113.917 133.333 108.333 127.75 108.333 120.833C108.333 113.917 113.917 108.333 120.833 108.333Z" fill="#F1F1F1" />
                                        </svg>
                                        <p className='font-weight-800'>No vouchers found</p>
                                        <p className='font-size-12'>To create a new voucher select 'Create Voucher'</p>
                                    </div>

                            )
                            :
                            (
                                (expiredVouchers.length > 0)
                                    ?
                                    <div>
                                        {
                                            expiredVouchers.map((voucher, index) =>
                                                <div className='height-100 bg-white col-11 d-flex mb-4 mx-auto overflow-hidden rounded-end-2 rounded-end-3 shadow-sm'>
                                                    <div className='align-items-center col-9 d-flex flex-column justify-content-center text-center'>
                                                        <p className='font-size-20 font-weight-700 text-wafaa-dark-blue mb-0'>VOUCHER</p>
                                                        <p className='text-wafaa-grey mb-0 font-size-12'>
                                                            Redeemed on
                                                            <span className='ms-1 font-weight-700'>{moment(voucher.Valid_Until).format('DD MMMM YYYY')}</span>
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-center align-items-center bg-warning col-3 m-0 text-center px-2 py-4'>
                                                        <span className='d-inline-block font-size-15 px-2 text-center text-white font-weight-700' style={{
                                                            transform: 'rotate(270deg)'
                                                        }}>
                                                            AED {voucher.Redeem_value}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    :
                                    <div className='text-center text-wafaa-dark-blue-2'>
                                        <svg width="200" height="200" viewBox="0 0 200 200" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M123.333 66.6666L133.333 76.6666L76.6666 133.333L66.6666 123.333L123.333 66.6666ZM33.3333 33.3333H166.667C175.917 33.3333 183.333 40.7499 183.333 49.9999V83.3333C178.913 83.3333 174.674 85.0892 171.548 88.2148C168.423 91.3404 166.667 95.5796 166.667 99.9999C166.667 104.42 168.423 108.659 171.548 111.785C174.674 114.911 178.913 116.667 183.333 116.667V150C183.333 159.25 175.917 166.667 166.667 166.667H33.3333C28.913 166.667 24.6738 164.911 21.5482 161.785C18.4226 158.659 16.6666 154.42 16.6666 150V116.667C25.9166 116.667 33.3333 109.25 33.3333 99.9999C33.3333 95.5796 31.5773 91.3404 28.4517 88.2148C25.3261 85.0892 21.0869 83.3333 16.6666 83.3333V49.9999C16.6666 45.5796 18.4226 41.3404 21.5482 38.2148C24.6738 35.0892 28.913 33.3333 33.3333 33.3333ZM33.3333 49.9999V71.1666C38.3973 74.0866 42.6029 78.2888 45.5271 83.3503C48.4512 88.4119 49.9908 94.1544 49.9908 99.9999C49.9908 105.845 48.4512 111.588 45.5271 116.649C42.6029 121.711 38.3973 125.913 33.3333 128.833V150H166.667V128.833C161.603 125.913 157.397 121.711 154.473 116.649C151.549 111.588 150.009 105.845 150.009 99.9999C150.009 94.1544 151.549 88.4119 154.473 83.3503C157.397 78.2888 161.603 74.0866 166.667 71.1666V49.9999H33.3333ZM79.1666 66.6666C86.0833 66.6666 91.6666 72.2499 91.6666 79.1666C91.6666 86.0832 86.0833 91.6666 79.1666 91.6666C72.25 91.6666 66.6666 86.0832 66.6666 79.1666C66.6666 72.2499 72.25 66.6666 79.1666 66.6666ZM120.833 108.333C127.75 108.333 133.333 113.917 133.333 120.833C133.333 127.75 127.75 133.333 120.833 133.333C113.917 133.333 108.333 127.75 108.333 120.833C108.333 113.917 113.917 108.333 120.833 108.333Z" fill="#F1F1F1" />
                                        </svg>
                                        <p className='font-weight-800 text-wafaa-dark-blue-2'>No vouchers found</p>
                                        <p className='font-size-12 text-wafaa-dark-blue-2'>To create a new voucher select 'Create Voucher'</p>
                                    </div>

                            )
                    }

                </div>

                <div className='position-absolute w-100 text-center bottom-60 mb-4 bg-transparent'>
                    <button type="button" className="bg-wafaa-purple btn text-white w-90" data-bs-toggle="modal" data-bs-target="#createVoucher">
                        Create Voucher
                    </button>
                    <button ref={generatedVoucherBtn} className="btn btn-primary d-none" data-bs-target="#generatedVoucher" data-bs-toggle="modal">Generate Voucher</button>
                </div>
            </div>

            <div className="modal fade max-width-700 start-50" style={{
                transform: "translateX(-50%)"
            }} id="createVoucher" tabIndex="-1" aria-hidden="true">
                <div className="h-auto modal-dialog max-width-700 w-100 position-absolute bottom-0 m-0">
                    <div className="bg-wafaa-grey-4 modal-content rounded-top-4">
                        <div className="modal-header border-0">
                            <button ref={closeCreateVoucher} type="button" className="align-items-center bg-danger btn d-flex font-size-25 height-30 justify-content-center ms-0 rounded-circle text-white width-30" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                            <h5 className="col font-weight-800 modal-title text-center text-dark-emphasis">Redeem Points</h5>
                        </div>
                        <div className="modal-body">
                            <p className='text-center font-size-14 text-wafaa-black-1'>
                                You have collected <span className='text-dark-emphasis font-weight-800'>{customerData.POINTS_TOTAL ?? 0}</span>.
                            </p>
                            <p className='text-center font-size-12 text-wafaa-black-1'>
                                How many points do you want to exchange?
                            </p>
                            <div className='col-10 d-flex justify-content-center mx-auto'>
                                <button onClick={() => handlePoints(-1)} className='align-items-center bg-white btn d-flex font-size-25 font-weight-700 justify-content-center rounded-circle shadow text-wafaa-dark-blue width-50 height-50'>
                                    -
                                </button>
                                <input
                                    value={pointsToRedeem}
                                    type='number'
                                    maxLength={parseInt((customerData.POINTS_TOTAL ?? 0)).toString().length}
                                    pattern="\d*"
                                    className='number-textfield border-0 mx-2 py-3 rounded-pill text-center text-dark-emphasis w-60 font-size-12'
                                    onChange={(event) => handleInput(event)} />
                                <button onClick={() => handlePoints(1)} className='align-items-center bg-white btn d-flex font-size-25 font-weight-700 justify-content-center rounded-circle shadow text-wafaa-dark-blue width-50 height-50'>
                                    +
                                </button>
                            </div>

                            <div className='bg-white col-10 mt-4 mx-auto shadow text-center rounded-2 py-2'>
                                <p className='font-size-12 mb-0'>You will receive a discount code for</p>
                                <p className='font-size-25 text-wafaa-dark-blue font-weight-700'>
                                    AED <span>{calculatedPoints ?? 0}</span>
                                </p>
                            </div>

                            <div className='col-10 mx-auto'>
                                <button type="button" className="bg-wafaa-purple btn text-white w-100 mt-3 rounded-pill" onClick={() => { createVoucher() }}>
                                    Generate Voucher
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade max-width-700 start-50" style={{
                transform: "translateX(-50%)"
            }} id="generatedVoucher" tabIndex="-1" aria-hidden="true">
                <div className="h-auto modal-dialog max-width-700 w-100 position-absolute bottom-0 m-0">
                    <div className="bg-wafaa-grey-4 modal-content rounded-top-4">
                        <div className="modal-header border-0">
                            <button type="button" className="align-items-center bg-danger btn d-flex font-size-25 height-30 justify-content-center ms-0 rounded-circle text-white width-30" data-bs-dismiss="modal" aria-label="Close">&times;</button>
                            <h5 className="col font-weight-800 modal-title text-center text-dark-emphasis">Voucher Generated!</h5>
                        </div>
                        <div className="modal-body">
                            <div className='border border-3 border-bottom-0 border-dark border-end border-start-0 border-top-0 d-flex justify-content-between text-end pe-2'>
                                <img src={subtract} alt='Subtract' />
                                <div className='font-size-12 text-wafaa-black-1'>
                                    <p className='mt-1 mb-0'>Voucher Value</p>
                                    <p className='mt-1 mb-0 font-weight-700'>AED <span>{createdVoucher.REEDEEM_AMOUNT ? createdVoucher.REEDEEM_AMOUNT : ''}</span></p>
                                </div>
                            </div>

                            <div className='bg-white col-10 mt-4 mx-auto shadow text-center rounded-2 py-2 mb-3'>
                                <p className='font-size-12 mb-0 text-wafaa-grey'>Voucher Code</p>
                                <p className='font-size-25 text-wafaa-dark-blue font-weight-700'>
                                    {createdVoucher.VOUCHER_NO ? createdVoucher.VOUCHER_NO : ''}
                                </p>
                            </div>

                            <p className='text-center font-size-12 text-wafaa-grey'>
                                Use this voucher you can pay in online store and in-stores
                            </p>

                            <hr />
                            <img className='js-bar-code width-150 mx-auto d-block mb-2' src='#' alt='Barcodes' />

                            <button className='bg-white btn btn-sm d-block mx-auto text-success w-85'>
                                Valid Until <span>{createdVoucher.EXPIRE_DATE ? moment(createdVoucher.EXPIRE_DATE).format('MMMM, DD YYYY') : ''}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar activePage='' />
        </AnimatedPage>
    );
}

export default Voucher;
