import React from 'react';
import 'bootstrap'
import './App.css';
import Auth from './components/Auth/Auth';
import Login from './components/Auth/Login';
import SignUp from './components/Auth/SignUp';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import Home from './components/Home/Home';
import HomeID from './components/Home/HomeID';
import Offers from './components/Offer/Offers';
import SingleOffer from './components/Offer/SingleOffer';
import History from './components/History/History';
import Voucher from './components/Voucher/Voucher';
import VoucherSingle from './components/Voucher/VoucherSingle';
import SingleTransaction from './components/History/SingleTransaction';
import DigitalReceipt from './components/History/DigitalReceipt';
import Profile from './components/Profile/Profile';
import MyProfile from './components/Profile/MyProfile';
import ForgotPassword from './components/Auth/ForgotPassword';
import ForgotPasswordOTP from './components/Auth/ForgotPasswordOTP';
import ForgotPasswordNewPassword from './components/Auth/ForgotPasswordNewPassword';
import SingleTransactionRedemption from './components/History/SingleTransactionRedemption';

import { UserProvider } from './context/userContext';
import SharePoints from './components/Profile/SharePoints';
import Alert from './components/Auth/Alert/Alert';
import useAlert from './hooks/Alert';
import { useAppSetting } from './context/appSettingContext';
import TransferVoucher from './components/Voucher/TransferVoucher';
import Feedback from './components/Profile/Feedback';
import Stores from './components/Stores/Store';
import About from './components/About/About';
import SingleAboutItemPage from './components/About/SingleAboutItemPage';

function App() {
  const location = useLocation();

  const { appSettings, theme, clientAccess } = useAppSetting();

  const { showAlert, alertStyle, alertMessage, hideAlert } = useAlert();

  function emitEvent (){
    const event = new Event('trueValuePWA');
    // Next, we dispatch the event.
    window.dispatchEvent(event);
  }

  if(appSettings !== undefined && clientAccess !== undefined){
    const baseURL = appSettings.AppBaseURL;

    const clientID = appSettings.ClientId;
  
    if (baseURL !== undefined && clientID !== undefined && appSettings !== undefined) {
      return (
        <div className={
          'container-fluid w-100 position-relative px-0 font-family-dmsans bg-light h-100 max-width-700 position-relative ' 
          + (theme === 'dark' ? 'dark' : '') 
          }>
          <div className='w-100 text-center position-fixed bg-black top-0 start-0 z-3 d-none' id='promptButton'>
            <button className='btn btn-primary' onClick={() => emitEvent()}>Install App</button>
          </div>
          <AnimatePresence mode='wait'>
            <UserProvider>
              <Routes key={location.pathname} location={location}>
                <Route
                  path='/'
                  element={
                    <Auth
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/login'
                  element={
                    <Login
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/forgot-password'
                  element={
                    <ForgotPassword
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/forgot-password-otp'
                  element={
                    <ForgotPasswordOTP
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/forgot-password-new-password'
                  element={
                    <ForgotPasswordNewPassword
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/join'
                  element={
                    <SignUp
                      baseURL={baseURL}
                      clientID={clientID}
                      appSettings = {appSettings}
                    />
                  }>
                </Route>
  
                <Route
                  path='/home'
                  element={
                    <Home
                    appSettings = {appSettings}
                    />
                  }>
                </Route>
  
                <Route
                  path='/home/id'
                  element={
                    <HomeID
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/offers'
                  element={
                    <Offers
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/offers/single/:promocode'
                  element={
                    <SingleOffer
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/history'
                  element={
                    <History
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/transactions/single/:billref'
                  element={
                    <SingleTransaction
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/transactions/single/redemption/:billref'
                  element={
                    <SingleTransactionRedemption
                      baseURL={baseURL}
                      clientID={clientID}
                    />
                  }>
                </Route>
  
                <Route
                  path='/digital-receipt'
                  element={
                    <DigitalReceipt />
                  }>
                </Route>
  
                <Route
                  path='/profile'
                  element={
                    <Profile
                      baseURL={baseURL}
                      clientID={clientID} />
                  }>
                </Route>
  
                <Route
                  path='/my-profile'
                  element={
                    <MyProfile
                      baseURL={baseURL}
                      clientID={clientID}
                      appSettings={appSettings}
                    />}>
                </Route>
  
                <Route
                  path='/vouchers'
                  element={
                    <Voucher baseURL={baseURL} clientID={clientID} />}>
                </Route>
  
                <Route path='/vouchers/single' element={<VoucherSingle />}></Route>

                <Route path='/vouchers/single/transfer' element={<TransferVoucher />}></Route>
  
                <Route path='/share-points' element={<SharePoints baseURL={baseURL} clientID={clientID} />}></Route>
                
                <Route path='/feedback' element={<Feedback baseURL={baseURL} clientID={clientID} />}></Route>

                <Route path='/stores' element={<Stores appSettings = {appSettings} />}></Route>

                <Route path='/about' element={<About appSettings = {appSettings} />}></Route>
                <Route path='/about/single' element={<SingleAboutItemPage appSettings = {appSettings} />}></Route>
  
              </Routes>
            </UserProvider>
          </AnimatePresence>
        </div>
      );
    } else {
      return (
        <div>
          <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
        </div>
      )
    }
  }else{
    return ''
  }
}

export default App;
