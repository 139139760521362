import React, { useRef, useState, useEffect } from 'react';
import AnimatedPage from '../FramerMotion/AnimatedPage';
import Title from './misc/Title';
import { useNavigate } from 'react-router-dom';
import calendar_icon from '../../images/icons/bi_calendar-date.svg';
import Alert from './Alert/Alert';
import Cookies from 'js-cookie';
import useAlert from "../../hooks/Alert";
import { useAuth } from '../../context/userContext';

function SignUp({ baseURL, clientID, appSettings }) {

    const [countries, setCountries] = useState([]);

    const [countriesFiltered, setCountriesFiltered] = useState([]);

    const [residenceCountry, setResidenceCountry] = useState('');

    const [countryCode, setCountryCode] = useState('');

    const [residenceFilter, setResidenceFilter] = useState('');

    const [residenceDisplay, setResidenceDisplay] = useState('height-0 opacity-0');

    const residenceUL = useRef();

    const [nationality, setNationality] = useState('');

    const [nationalityFilter, setNationalityFilter] = useState('');

    const [nationalityDisplay, setNationalityDisplay] = useState('height-0 opacity-0');

    const nationalityUL = useRef();

    const [gender, setGender] = useState('MALE');

    const [genderDisplay, setGenderDisplay] = useState('height-0 opacity-0');

    const genderUL = useRef();

    const [city, setCity] = useState('');

    const [cities, setCities] = useState([]);

    const [cityDisplay, setCityDisplay] = useState('height-0 opacity-0');

    const [cityCode, setCityCode] = useState('');

    const cityUL = useRef();

    const [area, setArea] = useState('');

    const [areas, setAreas] = useState([]);

    const [areaDisplay, setAreaDisplay] = useState('height-0 opacity-0');

    const [areaCode, setAreaCode] = useState('');

    const areaUL = useRef();

    const navigate = useNavigate();

    const { setAuthUser } = useAuth();

    const { showAlert, setShowAlert, alertStyle, setAlertStyle, alertMessage, setAlertMessage, hideAlert } = useAlert();

    const dateInput = useRef();
    const dateMaskInput = useRef();

    function setInputHeight(event, set = false) {
        if (event.type === 'focus' && !set) {
            event.target.classList.add('mt-4');
        }
        if (event.type === 'blur' && !set) {
            setTimeout(() => {
                if (event.target.value.trim() === '') {
                    event.target.classList.remove('mt-4');
                }
            }, 800);
        }
    }

    function openDate() {
        dateInput.current.showPicker();
        dateInput.current.focus();
    }

    function updateInputMask(event) {
        if (event.target.value === '') {
            dateMaskInput.current.classList.remove('mt-4');
        } else {
            dateMaskInput.current.value = event.target.value;
            dateMaskInput.current.classList.add('mt-4');
        }
    }

    async function sendSignUp(event, baseURL, closure) {
        const formData = new FormData(event.target.form);
        if (event.target.form.checkValidity()) {
            const payLoad = {
                "DIV_DES": "",
                "DIV_ID": "1",
                "FUNCTION": "LoyCreateCustomer",
                "SEND_KEY": "123456",
                "DATA": {
                    "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                    "TYPE_NAME": "CUSTOMER",
                    "CLIENT_ID": clientID,
                    "CUSTOMER_DETAILS":
                    {
                        "FIRSTNAME": formData.get('first_name'),
                        "MOBILE": formData.get('phone_number'),
                        "EMAIL": formData.get('email'),
                        "ADDR1": "addr1",
                        "ADDR2": "addr1",
                        "ADDR3": "addr3",
                        "BIRTHDATE": formData.get('date_of_birth'),
                        "VALID": "1",
                        "LASTNAME": formData.get('last_name'),
                        "LABOURCARDNO": "",
                        "LOCID": "",
                        "NATIONALITY": formData.get('nationality'),
                        "COUNTRY": formData.get('country'),
                        "GENDER": formData.get('gender'),
                        "CITY" : formData.get('city'),
                        "AREA": formData.get('area'),
                        "country_code": countryCode,
                        "city_code": cityCode,
                        "area_code": areaCode,
                    },
                    "LOGIN_DETAILS":
                    {
                        "USERNAME": formData.get('email'),
                        "PWORD": formData.get('password'),
                        "VALID": "Y"
                    }
                }
            }

            const request = await fetch(`${baseURL}/LoyCreateCustomer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            })

            try {
                const response = await request.json();

                if (request.ok) {
                    const secondPayload = {
                        "DIV_DES": "",
                        "DIV_ID": "1",
                        "FUNCTION": "LoyCreateCustomer",
                        "SEND_KEY": "123456",
                        "DATA": {
                            "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                            "TYPE_NAME": "MORE_CARDS",
                            "CARD_NO": response[0].Card_No,
                            "CUSTCODE": response[0].cust_Code,
                            "CARDNAME": "",
                            "CUST_BIRTHDAY": formData.get('date_of_birth'),
                            "CARD_ISSUE_DATE": "",
                            "CARD_EXP_DATE": "",
                            "ST_DATE": "",
                            "UPD_FLAG": "",
                            "CARD_TYPE": "PC",
                            "VALID": "1",
                            "FIRST_NAME": formData.get('first_name'),
                            "LAST_NAME": formData.get('last_name'),
                            "CARD_CATEGORY": "01",
                            "UPLOADED": "",
                            "ACTIVATED": "",
                            "CLIENT_ID": clientID
                        }
                    };

                    const secondRequest = await fetch(`${baseURL}/LoyCreateCustomer`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(secondPayload)
                    });

                    await secondRequest.json();

                    if (secondRequest.ok) {
                        hideAlert();
                        const loginPayLoad = {
                            "DIV_DES": "",
                            "DIV_ID": "1",
                            "FUNCTION": "LoyLogin",
                            "SEND_KEY": "123456",
                            "DATA": {
                                "AUTH_KEY": "TXlDb206TG95QVBJMTIz",
                                "USERNAME": formData.get('email'),
                                "PWORD": formData.get('password'),
                                "LOC_ID": "001",
                                "CLIENT_ID": clientID
                            }
                        }

                        const loginRequest = await fetch(`${baseURL}/LoyLogin`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(loginPayLoad)
                        })

                        try {
                            const loginResponse = await loginRequest.json();
                            if (loginRequest.ok) {
                                hideAlert();
                                setAlertStyle({
                                    background: 'linear-gradient(to right, #32bb71 0%, #2a9d8f 100%)'
                                })
                                setAlertMessage({
                                    success: true,
                                    message: 'Successfully logged in',
                                    mode: 'Sign Up'
                                });

                                Cookies.set('wafaa-user-data', JSON.stringify(loginResponse), { expires: 30 });

                                setShowAlert('d-block');

                                await setAuthUser(loginResponse);

                                setTimeout(() => {
                                    closure('/home');
                                }, 2000);
                            } else {
                                hideAlert();
                                setAlertStyle({
                                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                                })
                                setAlertMessage({
                                    success: false,
                                    message: loginResponse.MESSAGE,
                                    mode: 'Registration'
                                });
                                setShowAlert('d-block');
                            }
                        } catch (error) {
                            hideAlert();
                            setAlertStyle({
                                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                            })
                            setAlertMessage({
                                success: false,
                                message: 'Failed to login',
                                mode: 'Registration'
                            });
                            setShowAlert('d-block');
                        }
                    } else {
                        throw new Error('Invalid');
                    }
                } else {
                    throw new Error('Invalid');
                }
            } catch (error) {
                hideAlert();
                setAlertStyle({
                    background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
                })
                setAlertMessage({
                    success: false,
                    message: 'Failed to register',
                    mode: 'Registration'
                });
                setShowAlert('d-block');
            }
        } else {
            hideAlert();
            setAlertStyle({
                background: 'linear-gradient(to right, #f6743e 0%, #d42525 100%)'
            })
            setAlertMessage({
                success: false,
                message: 'Failed to register. Inputs not valid',
                mode: 'Registration'
            });
            setShowAlert('d-block');
        }

    }

    const handleResidenceFilter = (event) => {
        const filteredCountries = countries.filter((country) => country.en_short_name.toLowerCase().includes(event.target.value.toLowerCase()));
        setCountriesFiltered(filteredCountries);
        setResidenceFilter(event.target.value);
    }

    const handleResidenceSelect = () => {

        const listElements = residenceUL.current.querySelectorAll('li');

        const elementTop = residenceUL.current.getBoundingClientRect().top;

        let element, top, lowerMin = -100, lowerMax = -80, upperMin = 25, upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i].getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if ((top - elementTop) >= upperMin && (top - elementTop) <= upperMax) {
                    element = listElements[i];
                }
            }
        }
        setResidenceCountry(element.getAttribute('value'));
        setCountryCode(element.getAttribute('code'))
        setCountriesFiltered(countries);
        setResidenceFilter('');
        closeFilter('residence');
    }

    const handleNationalityChange = (event) => {
        const filteredCountries = countries.filter((country) => country.nationality.toLowerCase().includes(event.target.value.toLowerCase()));
        setCountriesFiltered(filteredCountries);
        setNationalityFilter(event.target.value);
    }

    const handleNationalitySelect = () => {
        const listElements = nationalityUL.current.querySelectorAll('li');

        const elementTop = nationalityUL.current.getBoundingClientRect().top;

        let element, top, lowerMin = -100, lowerMax = -80, upperMin = 25, upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i].getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if ((top - elementTop) >= upperMin && (top - elementTop) <= upperMax) {
                    element = listElements[i];
                }
            }
        }

        setNationality(element.getAttribute('value'));
        setCountriesFiltered(countries);
        setNationalityFilter('');
        closeFilter('nationality')
    }

    const handleGenderSelect = () => {
        const listElements = genderUL.current.querySelectorAll('li');

        const elementTop = genderUL.current.getBoundingClientRect().top;

        let element, top, lowerMin = -100, lowerMax = -80, upperMin = 25, upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i].getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if ((top - elementTop) >= upperMin && (top - elementTop) <= upperMax) {
                    element = listElements[i];
                }
            }
        }

        setGender(element.getAttribute('value'));
        closeFilter('gender')
    }

    const handleCitySelect = () => {
        const listElements = cityUL.current.querySelectorAll('li');

        const elementTop = cityUL.current.getBoundingClientRect().top;

        let element, top, lowerMin = -100, lowerMax = -80, upperMin = 25, upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i].getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if ((top - elementTop) >= upperMin && (top - elementTop) <= upperMax) {
                    element = listElements[i];
                }
            }
        }

        setCity(element.getAttribute('value'));
        setCityCode(element.getAttribute('code'));
        closeFilter('city')
    }

    const handleAreaSelect = () => {
        const listElements = areaUL.current.querySelectorAll('li');

        const elementTop = areaUL.current.getBoundingClientRect().top;

        let element, top, lowerMin = -100, lowerMax = -80, upperMin = 25, upperMax = 30;

        for (let i = 0; i < listElements.length; i++) {
            top = Math.abs(listElements[i].getBoundingClientRect().top);
            const difference = top - elementTop;

            if (difference < 0) {
                if (difference >= lowerMin && difference <= lowerMax) {
                    element = listElements[i];
                }
            } else {
                if ((top - elementTop) >= upperMin && (top - elementTop) <= upperMax) {
                    element = listElements[i];
                }
            }
        }

        setArea(element.getAttribute('value'));
        setAreaCode(element.getAttribute('code'));
        closeFilter('area')
    }

    const openFilter = (type) => {
        if (type === 'residence') {
            setResidenceDisplay('height-250 opacity-100')
        }
            
        if (type === 'nationality') {
            setNationalityDisplay('height-250 opacity-100')
        }

        if (type === 'gender') {
            setGenderDisplay('height-250 opacity-100')
        }

        if (type === 'city') {
            setCityDisplay('height-250 opacity-100')
        }

        if (type === 'area') {
            setAreaDisplay('height-250 opacity-100')
        }
    }

    const closeFilter = (type) => {
        if (type === 'residence') {
            setResidenceDisplay('height-0 opacity-0')
        }

        if (type === 'nationality') {
            setNationalityDisplay('height-0 opacity-0')
        }

        if (type === 'gender') {
            setGenderDisplay('height-0 opacity-0')
        }

        if (type === 'city') {
            setCityDisplay('height-0 opacity-0')
        }

        if (type === 'area') {
            setAreaDisplay('height-0 opacity-0')
        }
    }

    useEffect(() => {
        getCountries();
    },[]);

    async function getCountries(){
        const payLoad = {
            "DATA": {
                "client_id": 80623,
                "data_type": "M_COUNTRY"
            }
        }

        try {
            const request = await fetch(`${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            })
    
            const response = await request.json();
            
            setCountries(response.DATA);

            setCountriesFiltered(response.DATA);
        } catch (error) {
            console.error(error);
            alert('Cannot load countries');
        }
        
    }

    useEffect(()=>{
        getCities()
    }, [residenceCountry]);

    async function getCities(){
        const payLoad = {
            "DATA": {
                "client_id": 80623,
                "data_type": "M_CITY",
                "country_code": countryCode
            }
        }

        try {
            const request = await fetch(`${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            })
    
            const response = await request.json();
            
            setCities(response.DATA);
        } catch (error) {
            console.error(error);
            alert('Cannot get cities')
        }
    }

    useEffect(()=>{
        getAreas()
    }, [cityCode]);

    async function getAreas(){
        const payLoad = {
            "DATA": {
                "client_id": 80623,
                "data_type": "M_AREA",
                "country_code": countryCode,
                "city_code": cityCode
            }
        }

        try {
            const request = await fetch(`${appSettings.AppBaseURL1}CRM_DownloadCou_Ci_Ar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payLoad)
            })
    
            const response = await request.json();
            
            setAreas(response.DATA);
        } catch (error) {
            console.error(error);
            alert('Cannot get areas')
        }
    }

    return (
        <AnimatedPage>
            <Title name="SIGN UP" />
            <Alert showAlert={showAlert} alertStyle={alertStyle} alertMessage={alertMessage} hideAlert={hideAlert} />
            <div className='h-90'>
                <form className='bg-white h-100' autoComplete='off'>
                    <div className='w-85 mx-auto bg-white pb-5 h-95'>
                        <div className='font-size-12 h-100 overflow-y-scroll pt-4'>
                            <div className='form-group position-relative'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Title*</label>
                                <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='text' name='title' />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>First Name</label>
                                <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='text' name='first_name' />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Last Name</label>
                                <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='text' name='last_name' />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <img src={calendar_icon} alt='Calendar icon' className='position-absolute right-0' />
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Date of birth
                                </label>
                                <input readOnly ref={dateMaskInput} className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1'
                                    onClick={(event) => { setInputHeight(event, true); openDate() }} onBlur={(event) => setInputHeight(event)} type='text' />
                                <input className='height-0 left-0 opacity-0 position-absolute z-n1' ref={dateInput} onChange={(event) => updateInputMask(event)} type='date' name='date_of_birth' />
                            </div>
                            <p className='my-4 font-size-12 text-wafaa-grey d-none'>
                                To join True Value as an individual,you should be of legal age defined by your country of residence. Please refer to Terms & Conditions.
                            </p>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Phone Number</label>
                                <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='tel' name='phone_number' />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Gender</label>
                                <input value={gender} readOnly name='gender' required className='mt-4 outline-none border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => { setInputHeight(event); openFilter('gender') }} onBlur={(event) => setInputHeight(event)} />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Country</label>
                                <input value={residenceCountry} readOnly name='country' required className='outline-none border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => { setInputHeight(event); openFilter('residence') }} onBlur={(event) => setInputHeight(event)} />
                                <input type='hidden' value={countryCode} name='country_code' />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>City</label>
                                <input value={city} readOnly name='city' required className='outline-none border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => { setInputHeight(event); openFilter('city') }} onBlur={(event) => setInputHeight(event)} />
                                <input type='hidden' value={cityCode} name='city_code' />
                            </div>
                            <div className='form-group position-relative mt-4'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Area</label>
                                <input value={area} readOnly name='area' required className='outline-none border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => { setInputHeight(event); openFilter('area') }} onBlur={(event) => setInputHeight(event)} />
                                <input type='hidden' value={areaCode} name='area_code' />
                            </div>
                            <div className='form-group position-relative mt-4 mb-3'>
                                <label className='text-wafaa-grey position-absolute bg-white z-0'>Nationality</label>
                                <input value={nationality} name='nationality' readOnly required className='outline-none border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => { setInputHeight(event); openFilter('nationality') }} onBlur={(event) => setInputHeight(event)} />
                            </div>

                            <div className='pb-2'>
                                <div className='form-group position-relative mt-2'>
                                    <label className='text-wafaa-grey position-absolute bg-white z-0'>Email address</label>
                                    <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='email' name='email' />
                                </div>
                                <div className='form-group position-relative mt-3'>
                                    <label className='text-wafaa-grey position-absolute bg-white z-0'>Password*</label>
                                    <input className='font-size-16 border-0 border-bottom bg-transparent w-100 transition-0-2-ease-in position-relative z-1' onFocus={(event) => setInputHeight(event)} onBlur={(event) => setInputHeight(event)} type='password' name='password' />
                                </div>
                            </div>

                            <p className='font-size-15 font-weight-bolder text-wafaa-grey my-4 d-none'>
                                Communication Preferences
                            </p>
                            <p className='my-4 text-wafaa-grey d-none'>
                                We want to send you the latest offers and news.Let us know how you'd like to stay in touch.
                            </p>

                            <div className='d-none justify-content-between align-items-center text-wafaa-grey my-4'>
                                <p>SMS</p>
                                <div className="form-check form-switch">
                                    <input className="form-check-input font-size-20" type="checkbox" />
                                </div>
                            </div>

                            <div className='d-none justify-content-between align-items-center text-wafaa-grey my-4'>
                                <p>E-mail</p>
                                <div className="form-check form-switch">
                                    <input className="form-check-input font-size-20" type="checkbox" />
                                </div>
                            </div>

                            <p className='text-wafaa-grey d-none'>
                                *Service messages will be sent via one of the above channels to ensure you are kept up to date
                            </p>

                        </div>
                    </div>
                    <div className='text-center position-fixed bottom-10 bg-white w-100 height-50 pt-2 max-width-700'>
                        <button onClick={(e) => sendSignUp(e, baseURL, navigate)} type='button' className='w-85 btn btn-lg py-2-5 btn-primary bg-wafaa-purple border-wafaa-purple shadow-wafaa-btn font-size-12'>
                            Sign up
                        </button>
                    </div>
                </form>
            </div>

            <div className={'max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease ' + residenceDisplay}>
                <div className='h-100 w-100 position-relative bg-transparent'>
                    <div className='position-absolute z-2 top--15 right-10'>
                        <button className='btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100' onClick={() => closeFilter('residence')}></button>
                    </div>
                    <div className='overflow-hidden mb-3 pt-2'>
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <input value={residenceFilter} className='w-80 mx-auto mt-3' placeholder='Search' onInput={(event) => handleResidenceFilter(event)} />
                            <div className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50" style={{
                                transform: 'translateY(0)'
                            }}></div>
                            <ul className='max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5' ref={residenceUL}>
                                {
                                    countriesFiltered.length > 0 ? countriesFiltered.map((country, index) => (
                                        <li className='option-list list-group-item border-0 text-center' name={country.country_name} value={country.country_name} key={index} code={country.country_code}>
                                            {country.country_name}
                                        </li>
                                    ))
                                        :
                                        <li className='mb-0'>
                                            No result
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn bg-wafaa-purple w-90 text-white' onClick={() => { handleResidenceSelect() }}> DONE </button>
                    </div>
                </div>
            </div>

            <div className={'max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease ' + nationalityDisplay}>
                <div className='h-100 w-100 position-relative bg-transparent'>
                    <div className='position-absolute z-2 top--15 right-10'>
                        <button className='btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100' onClick={() => closeFilter('nationality')}></button>
                    </div>
                    <div className='overflow-hidden mb-3 pt-2'>
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <input value={nationalityFilter} className='w-80 mx-auto mt-3' placeholder='Search' onChange={(event) => handleNationalityChange(event)} />
                            <div className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50" style={{
                                transform: 'translateY(0)'
                            }}></div>
                            <ul className='max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5' ref={nationalityUL}>
                                {
                                    countriesFiltered.length > 0 ? countriesFiltered.map((country, index) => (
                                        <li className='option-list list-group-item border-0 text-center' name={country.country_name} value={country.country_name} key={index}>
                                            {country.country_name}
                                        </li>
                                    ))
                                        :
                                        <li className='mb-0'>
                                            No result
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn bg-wafaa-purple w-90 text-white' onClick={() => { handleNationalitySelect() }}> DONE </button>
                    </div>
                </div>
            </div>

            <div className={'max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease ' + genderDisplay}>
                <div className='h-100 w-100 position-relative bg-transparent'>
                    <div className='position-absolute z-2 top--15 right-10'>
                        <button className='btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100' onClick={() => closeFilter('gender')}></button>
                    </div>
                    <div className='overflow-hidden mb-3 pt-2'>
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <div className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50" style={{
                                transform: 'translateY(0)'
                            }}></div>
                            <ul className='max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5 mt-4' ref={genderUL}>
                                <li className='option-list list-group-item border-0 text-center' value="MALE">
                                    MALE
                                </li>
                                <li className='option-list list-group-item border-0 text-center' value="FEMALE">
                                    FEMALE
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn bg-wafaa-purple w-90 text-white' onClick={() => { handleGenderSelect() }}> DONE </button>
                    </div>
                </div>
            </div>

            <div className={'max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease ' + cityDisplay}>
                <div className='h-100 w-100 position-relative bg-transparent'>
                    <div className='position-absolute z-2 top--15 right-10'>
                        <button className='btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100' onClick={() => closeFilter('city')}></button>
                    </div>
                    <div className='overflow-hidden mb-3 pt-2'>
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <div className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50" style={{
                                transform: 'translateY(0)'
                            }}></div>
                            <ul className='max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5 mt-5' ref={cityUL}>
                                {
                                    cities.length > 0 ? cities.map((city, index) => (
                                        <li className='option-list list-group-item border-0 text-center' name={city.city_name} value={city.city_name} key={index} code={city.city_code}>
                                            {city.city_name}
                                        </li>
                                    ))
                                        :
                                        <li className='mb-0 text-center'>
                                            Select country first or no city present
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn bg-wafaa-purple w-90 text-white' onClick={() => { handleCitySelect() }}> DONE </button>
                    </div>
                </div>
            </div>

            <div className={'max-width-700 bg-white w-100 bottom-0 position-fixed z-2 transition-0-2-ease ' + areaDisplay}>
                <div className='h-100 w-100 position-relative bg-transparent'>
                    <div className='position-absolute z-2 top--15 right-10'>
                        <button className='btn btn-close btn-outline-secondary rounded-circle height-30 width-30 overflow-hidden bg-white opacity-100' onClick={() => closeFilter('area')}></button>
                    </div>
                    <div className='overflow-hidden mb-3 pt-2'>
                        <div className="picker w-100 d-inline-grid position-relative bg-white">
                            <div className="picker-window bg-secondary-subtle z-1 opacity-25 position-absolute w-100 start-0 top-50" style={{
                                transform: 'translateY(0)'
                            }}></div>
                            <ul className='max-width-700 list-group list-group-flush vw-100 overflow-y-scroll py-5 mt-5' ref={areaUL}>
                                {
                                    areas.length > 0 ? areas.map((area, index) => (
                                        <li className='option-list list-group-item border-0 text-center' name={area.area_name} value={area.area_name} key={index} code={area.area_code}>
                                            {area.area_name}
                                        </li>
                                    ))
                                        :
                                        <li className='mb-0 text-center'>
                                            Select city first or no area present
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button className='btn bg-wafaa-purple w-90 text-white' onClick={() => { handleAreaSelect() }}> DONE </button>
                    </div>
                </div>
            </div>

        </AnimatedPage>
    );
}

export default SignUp;
